import styled from "styled-components";

export const Root = styled.div`
  --curve: 40;
  box-sizing: border-box;
`;

export const Cards = styled.ul`
  display: grid;
  margin: 20px;
  list-style-type: none;
`;

export const Card = styled.a`
  position: relative;
  display: block;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
  min-height: 65vh;
  max-height: 75vh;
  min-width: 300px
  width: 27vw;
  box-shadow: 0 1px 50px 20px rgba(151, 102, 153, 0.35);
  z-index: 1
`;

export const CardImage = styled.img`
  width: 100%;
  height: auto;
  &:hover,
  transition: 0.3s ease-in-out;
`;

export const CardOverlay = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: #bca1aa;
  transform: translateY(100%);
  transition: 0.3s ease-in-out;

  ${Card}:hover & {
    transform: translateY(0);
  }
`;

export const CardHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  gap: 2em;
  padding: 1.5em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: #bca1aa;
  transform: translateY(-100%);
  transition: 0.3s ease-in-out;

  ${Card}:hover & {
    transform: translateY(0);
    padding-bottom: 0px;
  }
`;

export const CardArc = styled.svg`
  width: 80px;
  height: 80px;
  position: absolute;
  bottom: 100%;
  right: 0;
  z-index: 1;
`;

export const CardThumb = styled.img`
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;

export const CardTitle = styled.h3`
  font-size: 2em;
  color: #6a515e;
  font-family: Noto Serif, serif;
`;

export const CardTagline = styled.span`
  display: block;
  margin: 1em 0;
  font-family: "MockFlowFont";
  font-size: 0.8em;
  color: #d7bdca;
`;

export const CardStatus = styled.span`
  font-size: 1.5em;
  color: #d7bdca;
`;

export const CardDescription = styled.p`
  color: #d7bdca;
  font-family: "MockFlowFont";
  padding: 0.5em;
  padding-top: 0

  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
`;
