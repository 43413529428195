import React from 'react';
import NavigationBar from '../containers/Navbar/Design-one/navbar';
import Footer from '../containers/Footer/footer';
import { Box, Heading, Stack, Image, Text, Flex, ListIcon, ListItem, List, Center, useBreakpointValue, Divider } from '@chakra-ui/react';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Banner from '../components/Banners/With-Sign-Up';
import WinnieCarousel from '../components/Carousel/WinnieCarousel';
import Favicon from 'react-favicon';
import Logo from "../images/logo.png";
import SecondImage from "../images/medium-shot-smiley-couple-with-wooden-background.jpg";
import Fireworks from "../images/fireworks.jpg";
import Tatjana from "../images/image002.jpg";

const BestYearYet = () => {
  // Convert to bullet points
  const willPoints = [
    "We will find exactly what you personally want and don’t want.",

    "You will feel safer to take the steps needed.",

    "You will feel more joy to take the steps needed.",

    "We will find why you personally procrastinate and how to change procrastination, sabotage and avoidance patterns.",

    "We will find your core limiting beliefs that keep you stuck and block you from getting what you actually want and alchemise them into one of your biggest strengths.",

    "You will walk away with at least 2 goals/desires/intentions completely established and the ability to complete this process yourself with every other goal you set.",

    "You will have a more empowered mindset and feel more connected to yourself and to the strength within you.",
  ];
  let ref1 = React.useRef<HTMLInputElement>(null);
  const isMobile = useBreakpointValue({ base: true, md: false });

  function scrollTo(ref: any) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }



  return (
    <>
      <Favicon iconSize={32} url={Logo} />
      <NavigationBar scheduleACall={() => scrollTo(ref1)} buttons={[
        { label: 'ABOUT TATJANA', to: '/about', onClick: () => console.log('two') },
        { label: 'SERVICES', to: '/services', onClick: () => console.log('two') },
        { label: 'CLINICAL EFT, NLP & FEMANINE ENERGY', to: '/clincaleft&nlp', onClick: () => console.log('two') },
      ]} />
      <Stack p={'1%'} >
        <Box bgColor={'#D6C5C6'} p='1.25%' borderRadius={'lg'} >
          <Box bgColor={'#E0D3D4'} borderRadius={'lg'} >
            <Flex
              justifyContent={{ base: 'center', lg: 'space-around' }}
              alignItems={'center'}
              direction={{ base: 'column', lg: 'row' }}
              gap={{ base: 4, md: 6 }}
              p={{ base: 4, md: 8 }}
              ml={0}
            >
              <Stack gap={{ base: 4, md: 6 }} textAlign={{ base: 'center', lg: 'left' }}
              >
                <Box
                  position="relative"
                  border={'2px solid #D6C5C6'}
                  left={{ base: '5.5%', md: '3%' }}
                  width={{ base: '90%', md: '68em' }}
                  height={{ base: 'auto', md: '550px' }}
                  margin={{ base: '10px 0', md: '20px 0' }}
                  background="#F5F0F0"
                  borderRadius="50% / 10%"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textIndent="0.1em"
                  _before={{
                    content: "''",
                    position: "absolute",
                    top: "10%",
                    bottom: "10%",
                    right: "-5%",
                    left: "-5%",
                    background: "inherit",
                    borderRadius: "5% / 50%",

                  }}
                >
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="center"
                    justifyContent="center"
                    gap={{ base: 0, md: 6 }}
                  >
                    <Stack maxW={{ base: '100%', md: '50%' }} mt={{ base: 4, md: 0 }} flex={1}>
                      <Image zIndex="1" src={Fireworks} borderRadius="xl" />
                    </Stack>
                    <Box maxW={{ base: '100%', md: '45%' }} p={5}>
                      <Stack gap={{ base: 4, md: 10 }} textAlign={{ base: 'center', lg: 'left' }}>
                        <Stack zIndex="1">
                          {!isMobile &&
                            <Heading
                              as="h1"
                              fontFamily="Noto Serif, serif"
                              textAlign="center"
                              fontWeight={100}
                              fontSize={{ base: '2.5em', md: '3.7em' }}
                              color="#7b5e62"
                            >
                              Make 2025 Your Best Year Yet
                            </Heading>
                          }
                          {isMobile &&
                            <Stack gap={0}>
                              <Heading
                                as="h1"
                                fontFamily="Noto Serif, serif"
                                textAlign="center"
                                fontWeight={100}
                                fontSize={{ base: '2em', md: '3.7em' }}
                                color="#7b5e62"
                              >
                                Make 2025
                              </Heading>
                              <Heading
                                as="h1"
                                pb={2}
                                fontFamily="Noto Serif, serif"
                                textAlign="center"
                                fontWeight={100}
                                fontSize={{ base: '2em', md: '3.7em' }}
                                color="#7b5e62"
                              >
                                Your Best Year Yet
                              </Heading>
                            </Stack>
                          }
                          <Heading
                            as="h2"
                            fontFamily="Noto Serif, serif"
                            textAlign="center"
                            fontWeight={100}
                            fontSize={{ base: '1.3em', md: '1.7em' }}
                            color="#7b5e62"
                          >
                            A soulful, feminine approach to manifesting your desires for 2025
                          </Heading>
                          <Center mt={2}>
                            <Divider
                              borderWidth="1.2px"
                              borderRadius="xl"
                              borderColor="#D6C5C6"
                              maxW="90%"
                            />
                          </Center>
                          <Heading
                            fontFamily="Lato, serif"
                            textAlign="center"
                            fontWeight="300"
                            color="#7b5e62"
                            mt={{ base: 4, md: 10 }}
                            fontSize={{ base: '1.1em', md: '1.35em' }}
                          >
                            You can join in person or on zoom.
                          </Heading>
                          <Heading
                            fontFamily="Lato, serif"
                            textAlign="center"
                            fontWeight="300"
                            color="#7b5e62"
                            mt={{ base: 2, md: 1 }}
                            mb={{ base: 4 }}
                            fontSize={{ base: '1.1em', md: '1.35em' }}
                          >
                            The next course dates are Saturday 08/02 - Sunday 09/02/25.
                          </Heading>

                        </Stack>
                      </Stack>
                    </Box>
                  </Flex>
                  <Box
                    position="absolute"
                    width={{ base: '85%', md: '450px' }}
                    right={{ base: '7.5%', md: '3%' }}
                    bottom={{ base: '3%', md: '5%' }}
                    height={{ base: '0', md: '165px' }}
                    margin={{ base: '10px 0', md: '20px 0' }}
                    background="#E0D3D4"
                    borderRadius="50% / 10%"
                    color="white"
                    textAlign="center"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    textIndent="0.1em"
                    _before={{
                      content: "''",
                      position: "absolute",
                      top: "10%",
                      bottom: "10%",
                      right: "-5%",
                      left: "-5%",
                      background: "inherit",
                      borderRadius: "5% / 50%",
                    }}
                  >
                  </Box>
                </Box>
              </Stack>
            </Flex >

            <Flex
              justifyContent={{ base: 'center', lg: 'space-around' }}
              alignItems={'center'}
              direction={{ base: 'column', lg: 'row' }}
              gap={{ base: 4, md: 2 }}
              p={{ base: 4, md: 8 }}
              ml={0}
            >
              <Stack gap={{ base: 4, md: 6 }} textAlign={{ base: 'center', lg: 'left' }}
              >
                <Box
                  position="relative"
                  border={'2px solid #D6C5C6'}
                  left={{ base: '5.5%', md: '3%' }}
                  width={{ base: '90%', md: '68em' }}
                  height={{ base: 'auto', md: '550px' }}
                  margin={{ base: '10px 0', md: '20px 0' }}
                  background="#F5F0F0"
                  borderRadius="50% / 10%"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textIndent="0.1em"
                  _before={{
                    content: "''",
                    position: "absolute",
                    top: "10%",
                    bottom: "10%",
                    right: "-5%",
                    left: "-5%",
                    background: "inherit",
                    borderRadius: "5% / 50%",

                  }}
                >
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="center"
                    justifyContent="center"
                    gap={{ base: 0, md: 2 }}
                  >
                    <Stack zIndex={1} maxW={{ base: '100%', md: '45%' }} mt={{ base: 4, md: 0 }} gap={4}>
                      <Heading fontFamily="Lato, serif" textAlign={'center'} fontWeight="300" ml={isMobile ? '10%' : 0} maxW={isMobile ? '80%' : '100%'} color="#7b5e62" mt={4} fontSize={{ base: '1.4em', md: '1.8em' }}>
                        Join me for the “2025 My Best Year Yet” workshop weekend.
                      </Heading>
                      <Center>
                        <Heading fontFamily="Lato, serif" textAlign={'center'} fontWeight="300" color="#7b5e62" fontSize={{ base: '1.1em', md: '1.35em' }} maxW={'90%'}>
                          I am inviting you to set up 2025 with me, so it becomes a year that you love, that flows easy, and most of all gets you what you really want. As part of this weekend, we conclude with making a vision board, where we bring together all the magic we've created along the way.
                        </Heading>
                      </Center>
                    </Stack>
                    <Box maxW={{ base: '100%', md: '53.5%' }} p={5}>
                      <Stack gap={{ base: 4, md: 6 }} textAlign={{ base: 'center', lg: 'left' }}>
                        <Center zIndex={1} >
                          <Image src={Tatjana} maxH={'17em'} borderRadius={'lg'} />
                        </Center>
                        <Center zIndex={1}>
                          <Heading fontFamily="Lato, serif" textAlign={'center'} fontWeight="300" color="#7b5e62" fontSize={{ base: '1.1em', md: '1.35em' }} maxW={'90%'}>
                            The combined energy of the group environment will make the process even more powerful and magical. Participants from the previous years testify to that. As I am always growing so does the content and the depth of these conscious and unconscious processes.
                          </Heading>
                        </Center>
                      </Stack>
                    </Box>
                  </Flex>
                  {isMobile ?
                    <Box
                      position="absolute"
                      width="490px"
                      top={'0%'}
                      height="7em"
                      w={'88%'}
                      margin="20px 0"
                      background="#E0D3D4"
                      borderRadius="50% / 10%"
                      color="white"
                      textAlign="center"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textIndent="0.1em"
                      _before={{
                        content: "''",
                        position: "absolute",
                        top: "10%",
                        bottom: "10%",
                        right: "-5%",
                        left: "-5%",
                        background: "inherit",
                        borderRadius: "5% / 50%",
                      }}
                    >
                    </Box>
                    :
                    <Box
                      left={'0%'}
                      position="absolute"
                      width="490px"
                      height="375px"
                      margin="20px 0"
                      background="#E0D3D4"
                      borderRadius="50% / 10%"
                      color="white"
                      textAlign="center"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textIndent="0.1em"
                      _before={{
                        content: "''",
                        position: "absolute",
                        top: "10%",
                        bottom: "10%",
                        right: "-5%",
                        left: "-5%",
                        background: "inherit",
                        borderRadius: "5% / 50%",
                      }}
                    >
                    </Box>
                  }
                </Box>
              </Stack>
            </Flex >
            <Flex
              justifyContent={{ base: 'center', lg: 'space-around' }}
              alignItems="center"
              direction={{ base: 'column', lg: 'row' }}
              gap={{ base: 4, md: 6 }}
              p={{ base: 0, md: 8 }}
              ml={0}
            >
              <Stack gap={{ base: 4, md: 6 }} textAlign={{ base: 'center', lg: 'left' }}>
                <Box
                  left={{ base: '7.5%', md: '-0.5%' }}
                  position="relative"
                  width={{ base: '85%', md: '74em' }}
                  height={{ base: 'auto', md: '550px' }}
                  margin={{ base: '10px 0', md: '20px 0' }}
                  p={{ base: 8 }}
                  pt={{ base: 10 }}
                  background="#F5F0F0"
                  borderRadius="50% / 10%"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textIndent="0.1em"
                  _before={{
                    content: "''",
                    position: "absolute",
                    top: "10%",
                    bottom: "10%",
                    right: "-5%",
                    left: "-5%",
                    background: "inherit",
                    borderRadius: "5% / 50%",
                  }}
                >
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="center"
                    justifyContent="center"
                    gap={{ base: 4, md: 6 }}
                  >
                    <Stack maxW={{ base: '100%', md: '90%' }} gap={5}>
                      <Center>
                        <Heading
                          fontFamily="Lato, serif"
                          textAlign="center"
                          fontWeight="300"
                          color="#7b5e62"
                          fontSize={{ base: '1.1em', md: '1.35em' }}
                          zIndex={1}

                        >
                          Maybe it's your desire to finally meet your aligned partner, or get yourself unstuck,
                          find yourself in a fitter body, a better job, and living with ease. Maybe you don't
                          know what you want yet but know what you don't want.
                        </Heading>
                      </Center>
                      <Center>
                        <Divider
                          borderWidth="1.2px"
                          borderRadius="xl"
                          borderColor="#D6C5C6"
                          maxW="80%"
                        />
                      </Center>
                      <Center>
                        <Heading
                          fontFamily="Lato, serif"
                          textAlign="center"
                          fontWeight="300"
                          color="#7b5e62"
                          fontSize={{ base: '1.1em', md: '1.35em' }}
                          zIndex={1}

                        >
                          Whatever it is, becoming aware of what you want and what you don’t want, and then
                          setting a clear intention of what you would like to have happen this coming year
                          makes it 82% more likely to become your reality.
                        </Heading>
                      </Center>
                      <Center>
                        <Divider
                          borderWidth="1.2px"
                          borderRadius="xl"
                          borderColor="#D6C5C6"
                          maxW="72%"

                        />
                      </Center>
                      <Center>
                        <Heading
                          fontFamily="Lato, serif"
                          textAlign="center"
                          fontWeight="300"
                          color="#7b5e62"
                          fontSize={{ base: '1.1em', md: '1.35em' }}
                          zIndex={1}

                        >
                          Not only do we do that, we also then install your goals into your conscious and
                          unconscious mind and in your future timeline as if it has already happened. We deal
                          with procrastination, self-sabotage, fear, and limiting beliefs that block you from
                          feeling safe to do and be what you want.
                        </Heading>
                      </Center>
                      <Center>
                        <Divider
                          borderWidth="1.2px"
                          borderRadius="xl"
                          borderColor="#D6C5C6"
                          maxW="50%"
                          zIndex={1}

                        />
                      </Center>
                      <Center>
                        <Heading
                          fontFamily="Lato, serif"
                          textAlign="center"
                          fontWeight="300"
                          color="#7b5e62"
                          fontSize={{ base: '1.1em', md: '1.35em' }}
                          zIndex={1}
                        >
                          And then we do a good old-fashioned vision board so you can see and feel it every
                          day just by looking at it, reinforcing the neural pathways we have created to
                          support these desires becoming a reality in your life.
                        </Heading>
                      </Center>
                    </Stack>
                  </Flex>
                </Box>
              </Stack>
            </Flex>
            <Flex
              justifyContent={{ base: 'center', lg: 'space-around' }}
              alignItems={'center'}
              direction={{ base: 'column', lg: 'row' }}
              gap={{ base: 4, md: 6 }}
              p={{ base: 4, md: 8 }}
              ml={0}
            >
              <Stack gap={{ base: 4, md: 6 }} textAlign={{ base: 'center', lg: 'left' }}
              >
                <Box
                  position="relative"
                  border={'2px solid #D6C5C6'}
                  left={{ base: '2.5%', md: '3%' }}
                  width={{ base: '95%', md: '75em' }}
                  height={{ base: 'auto', md: '550px' }}
                  margin={{ base: '10px 0', md: '20px 0' }}
                  background="#F5F0F0"
                  minH={{ base: '130vh', md: '100vh' }}
                  borderRadius="50% / 10%"
                  textAlign="center"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center"
                  textIndent="0.1em"
                  p={isMobile ? 10 : 0}
                  _before={{
                    content: "''",
                    position: "absolute",
                    top: "10%",
                    bottom: "10%",
                    right: "-5%",
                    left: "-5%",
                    background: "inherit",
                    borderRadius: "5% / 50%",

                  }}
                >
                  <Flex
                    direction={{ base: 'column', md: 'row' }}
                    alignItems="center"
                    justifyContent="center"
                    gap={{ base: 0, md: 4 }}
                  >
                    <Stack zIndex={1} maxW={{ base: '100%', md: '55%' }} mt={{ base: 4, md: 0 }} flex={1} >
                      <Image src={SecondImage} borderRadius={'xl'} />
                    </Stack>
                    <Box maxW={{ base: '100%', md: '49%' }} p={0} pt={isMobile ? 4 : 0}>
                      <Stack gap={{ base: 4, md: 10 }} textAlign={{ base: 'center', lg: 'left' }}>
                        <ul style={{ listStyleType: 'disc', paddingLeft: '20px', zIndex: 1 }}>
                          {willPoints.map((item, index) => (
                            <li
                              key={index}
                              style={{
                                fontFamily: 'Lato, serif',
                                fontWeight: 350,
                                fontSize: '1.2em',
                                color: '#7b5e62',
                                marginBottom: '10px',
                              }}
                            >
                              <Text
                                as='h5'
                                fontSize={{ base: '1em', md: '1.2em' }}
                                textAlign={'start'}
                              >
                                {item}
                              </Text>
                            </li>
                          ))}
                        </ul>
                      </Stack>



                    </Box>
                  </Flex>
                  {!isMobile &&
                    <Box
                      position="absolute"
                      width="650px"
                      right={'.25%'}
                      height="700px"
                      margin="20px 0"
                      background="#E0D3D4"
                      borderRadius="50% / 10%"
                      color="white"
                      textAlign="center"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                      textIndent="0.1em"
                      _before={{
                        content: "''",
                        position: "absolute",
                        top: "10%",
                        bottom: "10%",
                        right: "-5%",
                        left: "-5%",
                        background: "inherit",
                        borderRadius: "5% / 50%",
                      }}

                    >
                    </Box>
                  }

                </Box>
              </Stack>
            </Flex >
            <Banner onClick={() => scrollTo(ref1)} hoverColour='#7b5e62' bgColour='#C1B1B2' header='Please text or email me for more information or to enrol.' subText='  I would love to have you with me in this workshop. The next course dates are Saturday, 8/2/25 and Sunday, 9/2/25.' ml='-2.3%' />
          </Box >

        </Box >
      </Stack >
      <WinnieCarousel />
      <div ref={ref1}>
        <Footer />
      </div>
    </>

  );
};

export default BestYearYet;