import React, { useState } from 'react';
import {
  Box,
  Button,
  Image,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useBreakpointValue,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { ChevronDownIcon, CloseIcon, HamburgerIcon } from '@chakra-ui/icons';
import { Link } from 'react-router-dom';
import TitleHover from '../../../components/Text/Hover-Title';
import Logo from "../../../images/updatedLogo.png";

interface NavigationBarButton {
  label: string;
  onClick: () => void;
  to: string;
}

interface NavigationBarProps {
  buttons: NavigationBarButton[];
  scheduleACall?: any;
}

const NavigationBar: React.FC<NavigationBarProps> = ({ buttons, scheduleACall }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: openCourses, onOpen: onOpenCourses, onClose: onCloseCourses } = useDisclosure();
  const [menuOpen, setMenuOpen] = useState(false);

  const isMobile = useBreakpointValue({ base: true, md: false });
  const options = [
    { title: 'THE EMPOWERED, FEMININE WOMAN', to: '/theempoweredfemininewoman' },
    { title: 'MAKE 2025 YOUR BEST YEAR YET', to: '/make2025yourbestyearyet' },
    { title: 'TAPPING INTO LOVE', to: '/courses&workshops' },
  ];

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <Box boxShadow="md" backgroundColor="#958080" transition="all 0.35s" p="2%">
      {isMobile ? (
        <Box display="flex" justifyContent="space-between" alignItems="center">
          {/* Logo on the left */}
          <Link to="/">
            <Image src={Logo} alt="tatjana-logo" maxH={'4em'} />
          </Link>
          {/* Hamburger menu toggle */}
          <IconButton
            aria-label="Open menu"
            icon={menuOpen ? <CloseIcon /> : <HamburgerIcon />}
            variant="outline"
            onClick={toggleMenu}
            color="#efecec"
            _hover={{ color: '#dbd1c9' }}
          />
          {menuOpen && (
            <VStack
              backgroundColor="#958080"
              boxShadow="md"
              position="absolute"
              top="60px"
              left="0"
              right="0"
              zIndex={10}
              p={4}
              textAlign="center" // Center-align text
            >
              {buttons.map((button, index) => (
                <Box key={index} p={2}>
                  <TitleHover
                    to={button.to}
                    as="h5"
                    size="sm"
                    title={button.label}
                    fontFamily="Lato, sans-serif"
                    color="#efecec"
                    hoverColor="#dbd1c9"
                  />
                </Box>
              ))}
              <Box onMouseEnter={onOpenCourses} onMouseLeave={onCloseCourses}>
                <Menu isOpen={openCourses}>
                  <MenuButton
                    as={Button}
                    color="#efecec"
                    rightIcon={<ChevronDownIcon />}
                    _hover={{ color: '#dbd1c9' }}
                    variant="unstyled"
                  >
                    COURSES & WORKSHOPS
                  </MenuButton>
                  <MenuList borderRadius="none" backgroundColor="#958080" border="none">
                    {options.map((option, index) => (
                      <MenuItem
                        key={index}
                        justifyContent="center"
                        backgroundColor="#958080"
                        mt={index === 0 ? 0 : 2}
                      >
                        <TitleHover
                          to={option.to}
                          as="h5"
                          size="sm"
                          title={option.title}
                          fontFamily="Lato, sans-serif"
                          color="#efecec"
                          hoverColor="#dbd1c9"
                        />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Box>
            </VStack>
          )}
        </Box>
      ) : (
        <HStack justify="center" gap={6} maxH="8vh">
          <Box minW="7vw" maxW="15vw" cursor="pointer">
            <Link to="/">
              <Image src={Logo} alt="tatjana-logo" />
            </Link>
          </Box>
          {buttons.map((button, index) => (
            <Box key={index} p={1} minW="7vw" maxW="15vw">
              <TitleHover
                to={button.to}
                as="h5"
                size="sm"
                title={button.label}
                fontFamily="Lato, sans-serif"
                color="#efecec"
                hoverColor="#dbd1c9"
              />
            </Box>
          ))}
          <Box p={1} minW="7vw" maxW="15vw">
            <Box onMouseEnter={onOpen} onMouseLeave={onClose}>
              <Menu isOpen={isOpen}>
                <MenuButton
                  as={Button}
                  color="#efecec"
                  rightIcon={<ChevronDownIcon />}
                  _hover={{ color: '#dbd1c9' }}
                  variant="unstyled"
                >
                  COURSES & WORKSHOPS
                </MenuButton>
                <MenuList borderRadius="none" backgroundColor="#958080" border="none">
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      justifyContent="center"
                      backgroundColor="#958080"
                      mt={index === 0 ? 0 : 2}
                    >
                      <TitleHover
                        to={option.to}
                        as="h5"
                        size="sm"
                        title={option.title}
                        fontFamily="Lato, sans-serif"
                        color="#efecec"
                        hoverColor="#dbd1c9"
                      />
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
            </Box>
          </Box>
          <Box key="schedule-a-call" p={1} minW="7vw" maxW="15vw">
            <Button
              p={5}
              pl={6}
              pr={6}
              fontFamily="Lato, sans-serif"
              fontWeight="500"
              rounded="full"
              variant="outline"
              borderColor="white"
              borderWidth="1px"
              fontSize={{ base: '1.2em', md: 'lg' }}
              onClick={scheduleACall}
              color="#efecec"
              _hover={{
                bgColor: '#D5D3D3',
                textColor: '#958080',
              }}
            >
              SCHEDULE A CALL
            </Button>
          </Box>
        </HStack>
      )}
    </Box>
  );
};

export default NavigationBar;
