import { Box, Center, Divider, Flex, Heading, Image, Link, List, ListIcon, ListItem, Stack, Text, useBreakpointValue, VStack } from '@chakra-ui/react';
import React from 'react';
import NavigationBar from '../Navbar/Design-one/navbar';
import Footer from '../Footer/footer';
import { ChevronRightIcon } from '@chakra-ui/icons';
import EftBadge from "../../images/eft-badge.png"
import WinnieCarousel from '../../components/Carousel/WinnieCarousel';
import Favicon from 'react-favicon';
import Logo from "../../images/logo.png";
import Park from "../../images/park3.jpg";
import Latest from "../../images/latest.png";
import Nine from "../../images/9.jpg";

const ClinicalEFT = () => {
  const nlpQuestions = [
    'Do I live with intention?',
    'How do I talk to myself on the inside?',
    'Am I acting motivated by fear or love?',
    'What is the art of emotional maturity?',
    'Am I able to communicate in a way that doesn’t trigger people’s defence system?',
    'Have I considered that we attract what we focus on and do I know what I want?',
    'What do I believe, is that really true, am I taking responsibility for my life like an adult can or do I feel victim to circumstances?',
    'Do my habits and behaviours line up with what I want, or do I feel like I have “a voice” inside me that tells me to do things that feel good in the moment, but I will regret or even feel ashamed of just an instant later?',
  ];
  let ref1 = React.useRef<HTMLInputElement>(null);

  function scrollTo(ref: any) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  const isMobile = useBreakpointValue({ base: true, md: false });




  return (
    <>
      <Favicon iconSize={32} url={Logo} />
      <NavigationBar scheduleACall={() => scrollTo(ref1)} buttons={[
        { label: 'ABOUT TATJANA', to: '/about', onClick: () => console.log('two') },
        { label: 'SERVICES', to: '/services', onClick: () => console.log('two') },
        { label: 'CLINICAL EFT, NLP & FEMININE ENERGY', to: '/clincaleft&nlp', onClick: () => console.log('two') },
      ]} />
      <Flex bgColor={'#E2D1C8'} p="0% 4% 0% 6%" direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="baseline" justifyContent="center" gap={8} >
        {!isMobile ?
          <>
            <Box p={5} pt={0} pos={'relative'}>
              <Stack gap={6} >
                <Box minH={'25em'} zIndex={1}>
                  <Box transform={'rotate(0deg)'} pos={'absolute'} left={'15%'} top={'-15%'} h={0}>
                    <Box
                      width={{ base: "100%", md: "380px" }} // Full width on mobile, specific width on desktop
                      height={{ base: "auto", md: "640px" }} // Auto height on mobile, fixed on desktop
                      borderRadius={{ md: "50% 50% 50% 50% / 60% 60% 40% 40%" }} // Egg shape on medium+ screens
                      bgColor={'#FCF0F5'}
                      color="white"
                      textAlign="center"
                      overflow="hidden"
                      m="auto"
                      transform={'rotate(90deg)'}
                    >
                      <Box transform={'rotate(-90deg)'} position={'absolute'} bottom={'26%'}>
                        <Heading as={'h1'} fontFamily='Noto Serif, serif' fontWeight={100} fontSize={'5.3em'} color="#7b5e62">Clinical EFT</Heading>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box pos={'absolute'} minW={'12.5em'} bottom={'-15%'} left={'300px'}>
                  <Flex gap={5}  >
                    <Image zIndex={1} maxW={{ base: '100%', md: '100%' }} src={EftBadge} />
                    <Center w='25px'>
                      <Divider zIndex={1} orientation='vertical' borderWidth={'1.25px'} borderColor={'#C8B3A4'} borderRadius={'xl'} maxH={'50%'} />
                    </Center>
                    <Image zIndex={1} maxW={{ base: '100%', md: '100%' }} src={Latest} />
                  </Flex>
                </Box>
                <Box transform={'rotate(0deg)'} pos={'absolute'} left={'850%'} top={'15%'}>
                  <Box
                    width={{ base: "100%", md: "380px" }} // Full width on mobile, specific width on desktop
                    height={{ base: "auto", md: "640px" }} // Auto height on mobile, fixed on desktop
                    borderRadius={{ md: "50% 50% 50% 50% / 60% 60% 40% 40%" }} // Egg shape on medium+ screens
                    bgColor={'#DCC5BA'}
                    color="white"
                    textAlign="center"
                    overflow="hidden"
                    m="auto"
                    transform={'rotate(270deg)'}
                  >
                  </Box>
                </Box>
              </Stack>
            </Box >
            <Box minW={'55%'}></Box>
          </>
          :
          <Box maxW={'90%'}>
            <Heading pl='7%' as={'h1'} mt={4} mb={6} fontFamily='Noto Serif, serif' fontWeight={100} fontSize={'3.5em'} textAlign={'center'} color="#7b5e62">Clinical EFT</Heading>
            <Flex gap={5} pl='5%' >
              <Image zIndex={1} maxW={'45%'} src={EftBadge} />
              <Center w='25px'>
                <Divider zIndex={1} orientation='vertical' borderWidth={'1.25px'} borderColor={'#C8B3A4'} borderRadius={'xl'} maxH={'50%'} />
              </Center>
              <Image zIndex={1} maxW={'45%'} src={Latest} borderRadius={'lg'} />
            </Flex>
          </Box>
        }
      </Flex >


      <Flex bgColor={'#E2D1C8'} p="5% 4% 0% 6%" direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" justifyContent="center" gap={8} >
        <Box maxW={{ base: '100%', md: '67.5%' }} p={5} pos={'relative'}>
          <Stack gap={6} >
            <Text
              fontFamily="Lato, serif"
              color="#7b5e62"
              fontWeight="300"
              fontSize={{ base: "1.27em", md: "1.45em" }}
              mt={isMobile ? 0 : 6}
            >
              There have been multiple clinical trials that showed Clinical EFT (Emotional Freedom Techniques) to be an effective treatment for anxiety, depression, emotional overwhelm, emotional triggers, emotional impact of memories and past traumatic events, food cravings, overeating, emotional distress, phobias, PTSD and physical pain. Clinical EFT works with both the conscious and the unconscious mind simultaneously. Clinical EFT creates change in the emotional areas of the brain and the body where painful memories are stored and stuck and where words alone can’t create change.


            </Text>
            <Box w={'55%'} h={'1px'} bgColor={'#C8B3A4'} mt={3} mb={2}></Box>
            <Text
              fontFamily="Lato, serif"
              color="#7b5e62"
              fontWeight="300"
              fontSize={{ base: "1.27em", md: "1.45em" }}
              maxW='95%' >
              Have you ever felt anxious, frustrated, hurt or resentful, and you tried to talk yourself out of it? Or distract yourself, but it affected you anyway? Did you often feel like these emotions took over? Maybe they surfaced as snarky comments, people pleasing, overthinking or even as a complete meltdown? Or, did they stopped you from acting towards what you want?


            </Text>
          </Stack>
        </Box>
        <Box maxW={isMobile ? '80%' : '35%'} zIndex={1}>
          <Image borderRadius={'lg'} src={Nine} />
        </Box>
      </Flex>



      <Flex bgColor={'#E2D1C8'} p="0% 4% 0% 5%" direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" justifyContent="center" gap={8} >
        <Box maxW={{ base: '100%', md: '97.5%' }} p={5} pt={2} pos={'relative'}>
          <Stack gap={6} >



            <Text
              fontFamily="Lato, serif"
              color="#7b5e62"
              fontWeight="300"
              fontSize={{ base: "1.27em", md: "1.45em" }}
              mt={2}
            >
              When we go through traumatic events, they have the following things in common. Our sense of power is taken away, the events are always unexpected, and we feel helpless and alone, facing some sort of danger, whether real or imagined. Often, we dissociate from our bodies to cope leaving all these emotions stuck and unprocessed. Even after the danger has passed, these emotions can be triggered repeatedly, or we may become numb to them.



            </Text>
            <Box w={'15%'} h={'1px'} bgColor={'#C8B3A4'}  ></Box>
            <Text
              fontFamily="Lato, serif"
              color="#7b5e62"
              fontWeight="300"
              fontSize={{ base: "1.27em", md: "1.45em" }}
              maxW='95%' >
              Time alone will not heal these wounds but tapping can. Clinical EFT helps to safely process and release these emotions while honouring the pain and fear of the experience. As we process these emotions, we regain our sense of power. Eventually, we still remember what happened, but it feels completely different than before. Through this process, we gradually move from feeling like a victim, filled with fear, to becoming our fully empowered adult selves.


            </Text>
            <Stack pb={4} gap={3}>
              <Text
                fontFamily="Lato, serif"
                color="#7b5e62"
                fontWeight="300"
                fontSize={{ base: "1.27em", md: "1.45em" }}
                maxW='95%' >
                I am an accredited Clinical & Evidenced Based EFT Practitioner, certified by Evidenced Based EFT Australia.
              </Text>
              <Text
                fontFamily="Lato, serif"
                color="#7b5e62"
                fontWeight="300"
                fontSize={{ base: "1.27em", md: "1.45em" }}
                maxW="95%"
              >
                <Link
                  href="https://www.evidencebasedeft.com/practitioners"
                  isExternal
                  textDecoration="underline"
                >
                  https://www.evidencebasedeft.com/practitioners
                </Link>
                &nbsp;(Please scroll down a little bit to find my name)
              </Text>
            </Stack>

          </Stack>
        </Box>
      </Flex>



      <Flex bgColor={'white'} p="4% 4% 6% 6%" direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" justifyContent="center" gap={isMobile ? 0 : 8} >
        <Box maxW={{ base: '100%', md: '50%' }} p={5}>
          <Flex>
            <Stack gap={6}>
              <Heading as={'h1'} fontFamily='Noto Serif, serif' fontWeight={200} size={'2xl'} color="#7b5e62">NLP mindset & life coaching
              </Heading>
              <Text
                fontFamily="Lato, serif"
                color="#7b5e62"
                fontWeight="300"
                fontSize={{ base: "1.27em", md: "1.45em" }}
                maxW='95%' >
                NLP (Neurolinguistic programming) is the study of how our inner world and “programming” runs us and our life, and how we can constantly reprogram ourselves through how we speak to ourselves and others, how we think and what we choose to believe. Consider the idea that 80% of what we get in life comes from what is going on inside of us and only 20% is the physical “how to” strategy. Meaning we absolutely want to tend to the inner work and together we'll work to answer these and more questions:
              </Text>
            </Stack>
          </Flex>
        </Box>
        <Stack maxW={{ base: '100%', md: '45%' }} mt={{ base: 4, md: 0 }} flex={1} gap={2} mb={isMobile ? 4 : 0}>
          <Box>
            <List spacing={2} flex={1}>
              {nlpQuestions.map((item, index) => (
                <ListItem as='h5' fontFamily='Lato, serif' key={index} fontWeight={350} fontSize='1.15em' color='#99817B'>
                  <Flex align="flex-start" >
                    <Box>
                      <Flex align="flex-start">
                        <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1.15em'} />
                        <Text as='h5' color={'#7b5e62'} fontSize={'1.25em'}> {item}</Text>
                      </Flex>
                    </Box>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
        </Stack>
      </Flex>


      <Flex bgColor={''} p="4% 2% 3% 6%" direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" justifyContent="center" gap={8} >
        <Stack maxW={{ base: '100%', md: '45%' }} mt={{ base: 4, md: 0 }} flex={1} gap={2}>
          <Image src={Park} borderRadius={'xl'} />
        </Stack>
        <Box maxW={{ base: '100%', md: '50%' }} p={5}>

          <Stack gap={3}>
            <Heading as={'h1'} fontFamily='Noto Serif, serif' fontWeight={200} size={'2xl'} pb={4} color="#7b5e62">Feminine energy</Heading>
            <Text
              fontFamily="Lato, serif"
              color="#7b5e62"
              fontWeight="300"
              fontSize={{ base: "1.27em", md: "1.4em" }}
              maxW='90%' >Feminine energy is a power that exists within all of us regardless of gender. It is characterized by qualities such as intuition, empathy, expressing needs and wants, receptivity, warmth, playfulness, creativity, and collaboration. In a world that often prioritizes more masculine traits like forcefulness, competition, and logic, understanding and embracing feminine energy is crucial for receiving balance and harmony in our lives.</Text>
          </Stack>
        </Box>
      </Flex>

      <Stack bgColor={''} p="4% 8%" pt="0" gap={7}>
        <Center
          fontFamily="Lato, serif"
          color="#7b5e62"
          fontWeight="300"
          fontSize={{ base: "1.35em", md: "1.4em" }}
        >
          <Text maxW={'90%'}>At its core, feminine energy is about flow rather than force. It's the gentle yet powerful current that guides us through life's webs and flows. Feminine energy is intuitive, attuned to the subtle rhythms of nature and the emotions within us and of those around us.
            It encourages us to listen to our inner voice and trust our instincts, guiding us towards paths that align with our true selves. For us women feminine energy is our superpower. It holds the ability to feel all of our emotions, from the depths of the ocean to the heights of the sky and let them move through our body without holding on to them.
          </Text>
        </Center>
        <Center
          fontFamily="Lato, serif"
          color="#7b5e62"
          fontWeight="300"
          fontSize={{ base: "1.27em", md: "1.4em" }}
        >
          <Text maxW={'90%'}>
            In a culture that glorifies busyness and productivity, feminine energy practices receptivity and surrender. It's about allowing us to be open and vulnerable, to receive the gifts that life and people have to offer with grace and gratitude. This doesn't mean passive acceptance or resignation; rather, it's a willingness to let go of control and trust in the wisdom of the universe. When we surrender to the flow of our feminine energy, we find peace and serenity amidst the chaos.

          </Text>
        </Center>
      </Stack>
      <WinnieCarousel />
      <div ref={ref1}>
        <Footer />
      </div>
    </>
  )
}

export default ClinicalEFT