import { As, ButtonProps, Heading, } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

interface TitleHoverProps extends ButtonProps {
  title: string
  color: string
  hoverColor: string
  as: As
  size: string
  to: string
}

const TitleHover: React.FC<TitleHoverProps> = ({ title, to, color, as, size, hoverColor }) => {
  const [titleHovered, setTitleHovered] = useState(false);
  return (
    <Link to={to}>
      <Heading
        as={as} size={size}
        fontWeight='500'
        maxW={'15em'}
        fontSize='17px'
        textAlign='center'
        color={titleHovered ? hoverColor : color}
        onMouseEnter={() => setTitleHovered(true)}
        onMouseLeave={() => setTitleHovered(false)}
      >
        {title}
      </Heading>
    </Link>
  );
};

export default TitleHover;