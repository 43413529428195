import { SimpleGrid, Flex, VStack, Heading, Divider, UnorderedList, ListItem, Center, HStack, Box, Text, List, ListIcon } from '@chakra-ui/react';
import header from "../images/header.jpg";
import mirror from '../images/mirror.jpg'
import React from 'react';
import ResponsiveBgCard from '../components/Cards/Responsive-background';
import WinnieCarousel from '../components/Carousel/WinnieCarousel';
import Hero from '../components/Heroes/Hero';
import ReverseHero from '../components/Heroes/Reverse';
import DesignHeader from '../containers/Header';
import NavigationBar from '../containers/Navbar/Design-one/navbar';
import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import Footer from '../containers/Footer/footer';
import Filip from "../images/filip.jpg";
import Struggle from "../images/struggling.jpg";
import SignUp from '../components/Sign-Up';
import Favicon from 'react-favicon';
import Logo from "../images/logo.png";
import Darius from "../images/darius-b.jpg";
import SeatedTatjana from "../images/23.jpg";

const Home = () => {
  const reverseHeroPoints = [
    "Reclaiming your power",
    "Calming your nervous system",
    "Feeling more confident and trusting in yourself",
    "Creating more connected relationships",
    "Attracting the love you desire",
    "Tuning into your feminine/masculine energy",
    "Becoming a magnet for what you want"
  ]
  const heroBulletPoints2 = [
    "Anxiety, insecurities and feeling powerless",
    "Attracting the right partner for you with whom you feel both chemistry and a true connection",
    "People pleasing tendencies and difficulties setting boundaries",
    "A past traumatic event that still feels raw",
  ];
  const heroBulletPoints3 = [
    "An anxious attachment style",
    "Knowing & expressing your needs in a way you can be heard",
    "Mental and/or emotional health",
    "Recovery &/or dating after divorce",
  ];
  const unStuckPoints = [
    "Reclaiming your power",
    "Dating & relationships",
    "Improving communication",
    "Mental & emotional health"
  ];
  const calmerPoints = [
    "Feel more resourced and confident in difficult or emotionally charged situations",
    "Trust yourself more",
    "Feel less anxious",
    "Feel happier, less tense & tired",
  ];
  const releasingPoints = [
    "Lighter and more relaxed",
    "Self empowered & more confident",
    "Filled with more energy",
    "More connected to yourself",
    "Able to make clearer decisions",
  ];
  let ref1 = React.useRef<HTMLInputElement>(null);

  function scrollTo(ref: any) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <Favicon iconSize={32} url={Logo} />
      <NavigationBar scheduleACall={() => scrollTo(ref1)} buttons={[
        { label: 'ABOUT TATJANA', to: '/about', onClick: () => console.log('two') },
        { label: 'SERVICES', to: '/services', onClick: () => console.log('two') },
        { label: 'CLINICAL EFT, NLP & FEMININE ENERGY', to: '/clincaleft&nlp', onClick: () => console.log('two') },
      ]} />
      <DesignHeader
        imageUrl={header}
        title='Transforming and empowering personal therapy for a calmer nervous system and more connecteting relationships'
      />
      <Center p={4}>
        <SimpleGrid p={'4% 5%'} columns={{ sm: 1, lg: 3 }} gap={10} color="#504043" textAlign='center' >
          <Flex flexDir='column' align='center' gap={5}>
            <VStack gap={1}>
              <Heading as="h3" fontFamily='Noto Serif, serif' fontSize='1.65em' color={'#99817B'} fontWeight={200}>A CALM NERVOUS SYSTEM</Heading>
              <Divider borderWidth='1.4px' borderRadius={10} borderColor="#A48E88" w={'85%'} />
            </VStack>
            <Box maxW='95%'>
              <Text fontSize='18px' fontFamily='Lato, serif' fontWeight={300} textColor='#504043' textAlign='center'>
                My clients feel calmer, more balanced, and energized. They notice less anxiety and fatigue, improved confidence, and better boundaries and self-expression.
              </Text>
            </Box>
          </Flex>

          <Flex flexDir='column' align='center' gap={5}>
            <VStack gap={1}>
              <Heading as="h3" fontFamily='Noto Serif, serif' fontSize='1.65em' color={'#99817B'} fontWeight={200}>TRAUMA</Heading>
              <Divider borderWidth='1.4px' borderRadius={10} borderColor="#A48E88" w={'85%'} />
            </VStack>
            <Box maxW='95%'>
              <Text fontSize='18px' fontFamily='Lato, serif' fontWeight={300} textColor='#504043' textAlign='center'>
                Trauma isn’t always from a big event and it can affect us even years later, manifesting in emotional upsets, people pleasing, anxiety, and unhelpful behaviour patterns. Time does not heal trauma but processing it within the unconscious mind, for example with clinical EFT, can.
              </Text>
            </Box>
          </Flex>

          <Flex flexDir='column' align='center' gap={5}>
            <VStack gap={1}>
              <Heading as="h3" fontFamily='Noto Serif, serif' fontSize='1.65em' color={'#99817B'} fontWeight={200}>DATING & RELATIONSHIPS</Heading>
              <Divider borderWidth='1.4px' borderRadius={10} borderColor="#A48E88" w={'85%'} />
            </VStack>
            <Box maxW='95%'>
              <Text fontSize='18px' fontFamily='Lato, serif' fontWeight={300} textColor='#504043' textAlign='center'>
                Clients find the guidance to "Dating with Intention" efficient and stress-free and together we nurture all aspects of yourself to bring your genuine, loving self to relationships. Learn a connecting way to communicate, to listen and be heard and to resolve conflict as it arises.
              </Text>
            </Box>
          </Flex>
        </SimpleGrid>
      </Center>
      <Hero
        title="If you're encountering challenges in these areas of your life:"
        titleSize='2xl'
        imageUrl={Struggle}>
        <HStack
          align="flex-start"
          spacing={2}
          gap={2}
          flexDirection={{ base: 'column', md: 'row' }} // Stack vertically on small screens, horizontally on larger ones
        >
          <Box flex={1} mr={{ base: 0, md: 4 }}> {/* Remove margin on smaller screens */}
            <List p="1% 0%" spacing={4} styleType="none">
              {heroBulletPoints2.map((header, index) => (
                <ListItem key={index}>
                  <Flex align="flex-start">
                    <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize="1.25em" />
                    <Heading
                      fontFamily="Lato, serif"
                      color="#99817B"
                      fontWeight="300"
                      fontSize="1.5em"
                    >
                      {header}
                    </Heading>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
          <Box flex={1} mr={{ base: 0, md: 4 }}>
            <List p="1% 0%" spacing={3} styleType="none">
              {heroBulletPoints3.map((header, index) => (
                <ListItem key={index}>
                  <Flex align="flex-start">
                    <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize="1.25em" />
                    <Heading
                      fontFamily="Lato, serif"
                      color="#99817B"
                      fontWeight="300"
                      fontSize="1.5em"
                    >
                      {header}
                    </Heading>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Box>
        </HStack>

      </Hero>
      <ReverseHero
        title="You deserve to shine your unique light"
        reverseHeroPoints={reverseHeroPoints}
        imageUrl={mirror}>
        <VStack align={'flex-start'} gap={3} maxW={'95%'}>
          <List p={4} pt={2} spacing={3} styleType="none">
            {reverseHeroPoints.map(header =>
              <ListItem >
                <Flex align="flex-start">
                  <ListIcon as={CheckIcon} color="#99817B" mt={1} fontSize={'1.15em'} />
                  <Heading fontFamily='Lato, serif' color={'#99817B'} fontWeight='300' fontSize={'1.4em'}>{header}</Heading>
                </Flex>
              </ListItem>
            )}
          </List>
        </VStack>
      </ReverseHero>
      <SignUp />

      <Center>
        <Heading fontFamily='Noto Serif, serif' maxW={{ base: '90%', md: '60%' }} pt={10} pb={4} fontSize={{ base: '2.75em', md: '3.75em' }} textAlign={'center'} fontWeight={200} color='#7B6E7B'>I guide people towards attaining these desired outcomes...</Heading>
      </Center>
      <VStack p='2% 3% 3% 3%' gap={8}>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={8} justifyContent={'center'} alignItems={'center'}>
          <ResponsiveBgCard title="Getting unstuck" description="Let Tatjana help you move forward in the areas of..." imageSrc={SeatedTatjana}>
            <VStack align={'flex-start'} gap={2} pb={4}>
              {unStuckPoints.map(header =>
                <UnorderedList >
                  <ListItem as='h5' fontFamily='Lato, serif' fontWeight={350} fontSize='1.1em' color='#7b5e62'>
                    <Flex align="flex-start" >
                      <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1em'} />
                      <Heading as='h5' fontFamily='Lato, serif' textColor='#5E4F4C' fontWeight='350' fontSize={'1.3em'}>{header}</Heading>
                    </Flex>
                  </ListItem>
                </UnorderedList>
              )}
            </VStack>
          </ResponsiveBgCard>
          <ResponsiveBgCard title="Releasing emotional challenges" description="Tatjana can support you to feel..." imageSrc={Filip}>
            <VStack align={'flex-start'} gap={2} pb={4} >
              {releasingPoints.map(header =>
                <UnorderedList >
                  <ListItem as='h5' fontFamily='Lato, serif' fontWeight={350} fontSize='1.1em' color='#7b5e62'>
                    <Flex align="flex-start" >
                      <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1em'} />
                      <Heading as='h5' fontFamily='Lato, serif' textColor='#5E4F4C' fontWeight='350' fontSize={'1.3em'}>{header}</Heading>
                    </Flex>
                  </ListItem>
                </UnorderedList>
              )}
            </VStack>
          </ResponsiveBgCard>
          <ResponsiveBgCard title="Cultivating a more centred nervous system" description={"This helps you to..."} imageSrc={Darius}>
            <VStack align={'flex-start'} gap={2} pb={4} >
              {calmerPoints.map(header =>
                <UnorderedList >
                  <ListItem as='h5' fontFamily='Lato, serif' fontWeight={350} fontSize='1.1em' color='#7b5e62'>
                    <Flex align="flex-start" >
                      <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1em'} />
                      <Heading as='h5' fontFamily='Lato, serif' textColor='#5E4F4C' fontWeight='350' fontSize={'1.3em'}>{header}</Heading>
                    </Flex>
                  </ListItem>
                </UnorderedList>
              )}
            </VStack>

          </ResponsiveBgCard>
        </SimpleGrid>
      </VStack>
      <WinnieCarousel />
      {/* <EmailForm /> */}
      <div ref={ref1}>
        <Footer />
      </div>
    </>
  );
};

export default Home;