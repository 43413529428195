import React from 'react'
import { Text, useBreakpointValue } from '@chakra-ui/react';


interface StyledHeaderProps {
  header: string
  as: string
  fontSize?: string
  color?: string
}

const StyledHeader: React.FC<StyledHeaderProps> = ({ header, fontSize = '2em', as, color = '#7b5e62' }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Text
      as="span"
      display="inline-block"
      textAlign={'center'}
      fontSize={fontSize}
      lineHeight={isMobile ? '1.2' : '1.5'}
      color={color}
      fontWeight={100}
      fontFamily='Noto Serif, serif'
      position="relative"
      _after={{
        content: '""',
        position: 'absolute',
        width: '100%',
        height: '2.5px',
        backgroundColor: '#bca1aa',
        bottom: isMobile ? '0px' : '5px', // Adjust this value to ensure the underline doesn't overlap with the descenders
        left: 0,
      }}
    >
      {header}
    </Text>
  )
}

export default StyledHeader