import React from 'react';
import { As, Box, Center, Flex, HStack, Heading, Image, Stack, VStack, useBreakpointValue } from '@chakra-ui/react';


interface HeroProps {
  title: string;
  subtext?: string;
  imageUrl: string;
  titleAs?: As;
  titleSize?: string;
  children?: any;
}

const Hero: React.FC<HeroProps> = ({
  title,
  titleAs = 'h1',
  titleSize = '2xl',
  imageUrl,
  children
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (

    <Stack p={'5%'} bg='#D0BEB8' >
      <Box>
        {isMobile ? (
          <VStack justify="center" spacing={6}>
            <Heading as={titleAs} fontFamily="Noto Serif, serif" textAlign={'center'} fontWeight={200} size={titleSize} color="#7b5e62">
              {title}
            </Heading>
            <Stack spacing={6}>
              {children}
            </Stack>
            <Image src={imageUrl} maxH="40vh" alt="Hero Image" borderRadius="lg" />
            <Heading as="h5" pt={6} fontFamily="Noto Serif, serif" fontWeight={200} color="#7b5e62" fontSize="26px" textAlign={'center'}>
              I'm looking forward to supporting you in your efforts to create change
            </Heading>

          </VStack>
        ) : (
          <HStack justify="center" gap={9}>
            <Image src={imageUrl} maxH="40vh" alt="Hero Image" borderRadius="lg" />
            <Flex>
              <Center>
                <Box w={'0.5px'} bgColor={'#BCA7A7'} mr={6} h={'50vh'} />
              </Center>
              <Box>
                <Center>
                  <Heading textAlign={'center'} maxW={'85%'} as={titleAs} fontFamily="Noto Serif, serif" fontWeight={200} size={titleSize} color="#7b5e62">
                    {title}
                  </Heading>
                </Center>
                <HStack gap={10} pt={6}>
                  <Stack>{children}</Stack>
                </HStack>
                <Heading fontFamily='Lato, serif' textAlign={'center'} color={'#99817B'} fontWeight='300' fontSize={'1.5em'} as="h5" pt={8}   >
                  I'm looking forward to supporting you in your efforts to create change
                </Heading>
              </Box>
            </Flex>
          </HStack>
        )}
      </Box>
    </Stack >
  );
};

export default Hero;


