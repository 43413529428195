import { Flex, Heading, Text, Image, Box, Divider, useColorModeValue, Stack, Center, List, ListIcon, ListItem, useBreakpointValue, Button, Link } from '@chakra-ui/react';
import Naomie from '../../images/naomie.png';
import SeatedTatjana from '../../images/seatedTatjana.png';

import { CheckIcon, ChevronRightIcon } from '@chakra-ui/icons';
import PinkHeart from "../../images/pinkHeart.png";
import Hand from "../../images/Feminine_Hand_No_Shadows-removebg-preview.png"
import Favicon from 'react-favicon';
import Logo from "../../images/logo.png";
import Couple from "../../images/couple.jpg";

const LoveMasterclass = () => {
  const learningPoints = [
    'How to quickly process emotional triggers',
    'A powerful repeatable step by step tapping routine you can do yourself at any time to get you unstuck',
    'How EFT Tapping can bring more romance, cherishing and closeness to your relationships'
  ];
  const youllGetPoints = [
    'A 1.5 hour recorded masterclass with Tatjana and Naomie teaching you the exact steps to master tapping on an emotion or trigger you notice in your body',
    'A tapping sequence guide so you can use this tool in your life any time to get you unstuck'
  ];
  const findingYourselfPoints = [
    'Feeling emotionally triggered around men and love',
    'Feeling hopeless around love and often believing it’s never going to happen for you',
    'Wondering if you’re too sensitive for dating, maybe online dating feels too traumatic',
    'Feeling almost “crazy” sometimes when your emotions get the better of you and come out all at once.',
    'Feeling resentful and angry for not being heard in your relationship and trying to stuff these feelings down so you don’t rock the boat',
    'Holding back communicating your needs, wants and desires because you’re afraid your emotions will be ‘too much’',
  ];
  const incrediblePoints = [
    'Feeling resentful because he did something that you perceive as inconsiderate',
    'Feeling small around a man you like, putting him on a pedestal',
    'Feeling overwhelmingly needy, and chasing crumbs',
    'Feeling doubtful you can have what you want',
    'Believing there are no aligned men out there',
    'The sinking, nauseous feeling when looking at the dating apps',
    'Feeling annoyed and angry with him',
    'Fear before a date',
    'Shame or fear of having said the wrong thing',
    'Waiting for his call and feeling urgent and awful whilst you wait',
    'Feeling afraid to trust in case youre let down again',
  ];

  const isMobile = useBreakpointValue({ base: true, md: false });


  return (

    <Box bgColor={'white'} p='1%'>
      <Favicon iconSize={32} url={Logo} />
      <Box bgColor={"#DDADAF"} minH={'100vh'} p='0% 3%' pb={'2%'}>
        <Flex
          direction={useBreakpointValue({ base: 'column', md: 'row' })}

          gap={6}
        >
          {/* Pink Background Section */}
          <Box
            bg={useColorModeValue('red.100', 'red.200')}
            boxShadow={'lg'}
            borderRadius="lg"
            p={4}
            mt={8}
            position="relative"
            h={{ base: 'auto', md: '30em' }}
            minW={{ md: '58em' }}
            display="flex"
            alignItems="flex-end"
          >
            <Stack
              gap={{ base: 4, md: 6 }}
              textAlign={{ base: 'center', lg: 'left' }}
            >
              <Text
                fontFamily="Lato, serif"
                color="#99817B"
                fontWeight={100}
                fontSize={{ base: '5em', md: '7.3em' }}
                lineHeight="1.05em"
              >
                Tapping into <Text as="span" color="brown">love</Text>
              </Text>
              <Heading
                minW="18vw"
                fontFamily="Lato, serif"
                fontSize="1.7em"
                ml={{ base: 0, md: '2%' }}
                fontWeight={300}
                as="i"
                color="#7b5e62"
              >
                With Tatjana Walton & Naomie Thompson
              </Heading>
            </Stack>
          </Box>
        </Flex>

        <Flex mt={'5%'} direction={useBreakpointValue({ base: 'column', md: 'row' })} pl={3} gap={isMobile ? 0 : 10} justifyContent="center" mb={isMobile ? 0 : 1}>
          <Stack maxW={{ base: '100%', md: '42%' }} mt={{ base: 0, md: 8 }} flex={1} gap={'5%'} position="relative">
            <Flex gap={5} align={'center'} mb={10}>
              <Box maxW={isMobile ? '40vw' : '17vw'}>
                <Image borderRadius={'md'} src={SeatedTatjana} />
              </Box>
              <Divider orientation="vertical" borderColor={useColorModeValue('red.100', 'red.200')} height={'20vh'} />
              <Box maxW={isMobile ? '40vw' : '17vw'}>
                <Image src={Naomie} borderRadius={'md'} />
              </Box>

            </Flex>
            <Box boxShadow={'lg'} mb={isMobile ? '55%' : 10} mt={isMobile ? 0 : 10} bg={useColorModeValue('red.100', 'red.200')} borderRadius="lg" p={7} w={isMobile ? ' 100%' : '110%'}>
              <Heading textAlign={'center'} fontFamily="Noto Serif, serif" fontSize="1.8rem" color="#7b5e62" fontWeight={200} mb={3}>
                Or have ever experienced these scenarios...
              </Heading>
              <List spacing={3} flex={1}>
                {incrediblePoints.map((item, index) => (
                  <ListItem
                    as="h5"
                    fontFamily="Lato, serif"
                    key={index}
                    fontWeight={350}
                    fontSize="1.1em"
                    color="#7b5e62"
                  >
                    <Flex align="flex-start">
                      <Box>
                        <Flex align="flex-start">
                          <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1.25em'} />
                          <Text as="h5" fontSize={'1.25em'}>
                            {item}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                  </ListItem>
                ))}
              </List>
            </Box>
          </Stack>

          <Box maxW={{ base: '100%', md: '58%' }} >
            <Box position="relative">
              {/* Pink Heart Image */}
              <Image borderRadius={'md'} src={PinkHeart} zIndex={0} />

              {/* Overlayed Text/List */}
              <Box
                position="absolute"
                top="50%"
                left="50%"
                bg={useColorModeValue('red.100', 'red.200')}
                transform="translate(-50%, -49%)"
                zIndex={1}
                borderRadius="lg"
                minW={isMobile ? '100%' : '80%'}
                p={6}
                boxShadow={'lg'}
              >
                <Heading textAlign={'center'} fontFamily="Noto Serif, serif" fontSize="2rem" color="#7b5e62" fontWeight={200} mb={3}>
                  If you're finding yourself
                </Heading>
                <List spacing={3} flex={1}>
                  {findingYourselfPoints.map((item, index) => (
                    <ListItem
                      as="h5"
                      fontFamily="Lato, serif"
                      key={index}
                      fontWeight={350}
                      fontSize="1.1em"
                      color="#7b5e62"
                    >
                      <Flex align="flex-start">
                        <Box>
                          <Flex align="flex-start">
                            <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1.25em'} />
                            <Text as="h5" fontSize={'1.25em'}>
                              {item}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Box>
            </Box>
            <Center p={10} mt={isMobile ? '100%' : 0}>
              {!isMobile &&
                <Image p={5} borderRadius={'md'} pb={2} src={Couple} />
              }
            </Center>
          </Box>
        </Flex>


        <Flex
          boxShadow={'lg'}

          p={5}
          bg={useColorModeValue('red.100', 'red.200')}
          borderRadius={'lg'}
          direction={useBreakpointValue({ base: 'column', md: 'row' })}
          alignItems="center"
          gap={isMobile ? 0 : 6}
        >
          <Box maxW={{ base: '100%', md: '30%' }} p={5}>
            <Stack gap={{ base: 4, md: 3 }} p={5} borderRadius={'lg'} textAlign={{ base: 'center', lg: 'left' }} bgColor={'#DEA1A3'}>
              <Text
                as="h5"
                mb="10%"
                textAlign="center"
                fontWeight={300}
                fontFamily="Lato, serif"
                fontSize="1.66em"
                color="#F5E1E2"
              >
                & what you{' '}
                <Text as="span" fontStyle="italic">
                  really
                </Text>{' '}
                want is...
              </Text>
              <Heading
                textAlign="center"
                as="h3"
                fontFamily="Noto Serif, serif"
                fontWeight={200}
                fontSize="2.73em"
                pb={1}
                color="#F8EBEB"
              >
                More love!
              </Heading>
              <Center>
                <Divider borderColor={'#F5E1E2'} w={'60%'} />
              </Center>
              <Heading
                textAlign="center"
                as="h3"
                fontFamily="Noto Serif, serif"
                fontWeight={200}
                fontSize="2.73em"
                pb={1}
                color="#F8EBEB"
              >
                More intimacy!
              </Heading>
              <Center>
                <Divider borderColor={'#F5E1E2'} w={'70%'} />
              </Center>
              <Heading
                textAlign="center"
                as="h3"
                fontFamily="Noto Serif, serif"
                fontWeight={200}
                fontSize="2.73em"
                pb={1}
                color="#F8EBEB"
              >
                Feeling heard!
              </Heading>
              <Text
                as="h5"
                mt={4}
                textAlign={'center'}
                fontWeight={300}
                fontFamily="Lato, serif"
                fontSize="1.5em"
                color="#F5E1E2"
              >
                As well as feeling adored for exactly who you are... your vulnerabilities, quirks, and all!
              </Text>
            </Stack>
          </Box>

          {!isMobile &&
            <Center h={'35em'}>
              <Divider orientation="vertical" borderColor={'#B18A8C'} mx="auto" />
            </Center>
          }

          <Stack maxW={{ base: '100%', md: '70%' }} mt={{ base: 4, md: 0 }} gap={2} flex={1} p={isMobile ? 0.5 : 5} pt={0}>
            <Heading
              pt={8}
              textAlign={'center'}
              as={'h3'}
              fontFamily="Noto Serif, serif"
              fontWeight={200}
              fontSize="2.5em"
              color="#7b5e62"
            >
              Then this 1.5-hour masterclass is for you!
            </Heading>
            <Text
              textAlign={'center'}
              fontWeight={300}
              fontFamily="Lato, serif"
              fontSize="1.45em"
              color="#7b5e62"
            >
              In this masterclass we use Clinical EFT, or 'tapping', to allow the emotional grip to loosen so that love and communication becomes easier and softer. When you feel a stressful emotion or a trigger and you use this routine, you'll notice you feel softer, clearer, more at ease. Instead of withdrawing from dating or not expressing your needs in your relationship; you'll feel a new calm confidence and ability to handle the big stuff and not sweat the small stuff.
            </Text>
            <Flex
              mt={6}
              direction={useBreakpointValue({ base: 'column', md: 'row' })} // Adjust layout based on screen size
              gap={6} // Adds spacing between stacked components
              alignItems="start"
            >
              <Stack maxW={{ base: '100%', md: '49%' }}>
                <Heading
                  textAlign="center"
                  as={'h3'}
                  fontFamily="Noto Serif, serif"
                  mb={1}
                  fontWeight={200}
                  fontSize="2.25em"
                  color="#7b5e62"
                >
                  You'll learn
                </Heading>
                <List spacing={4}>
                  {learningPoints.map((learningPoint, index) => (
                    <ListItem key={index}>
                      <Flex align="flex-start">
                        <ListIcon as={CheckIcon} color="#7b5e62" mt={1} fontSize={'1.15em'} />
                        <Heading
                          fontFamily="Lato, serif"
                          color="#7b5e62"
                          fontWeight="300"
                          fontSize="1.4em"
                        >
                          {learningPoint}
                        </Heading>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Stack>

              <Stack maxW={{ base: '100%', md: '48%' }}>
                <Heading
                  textAlign="center"
                  as={'h3'}
                  fontFamily="Noto Serif, serif"
                  mb={1}
                  fontWeight={200}
                  fontSize="2.25em"
                  color="#7b5e62"
                >
                  You'll get
                </Heading>
                <List spacing={4}>
                  {youllGetPoints.map((learningPoint, index) => (
                    <ListItem key={index}>
                      <Flex align="flex-start">
                        <ListIcon as={CheckIcon} color="#7b5e62" mt={1} fontSize={'1.15em'} />
                        <Heading
                          fontFamily="Lato, serif"
                          color="#7b5e62"
                          fontWeight="300"
                          fontSize="1.4em"
                        >
                          {learningPoint}
                        </Heading>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </Stack>
            </Flex>
            <Flex mt={2}>
              <Button
                fontFamily="Lato, serif"
                fontWeight="300"
                rounded={'full'}
                variant="outline"
                borderColor="transparent"
                borderWidth={'1.75px'}
                transition={'0.3s'}
                fontSize={'2xl'}
                name="subscribe"
                id="mc-embedded-subscribe"
                value="Subscribe"
                type="submit"
                bgColor='#DEA1A3'
                color="#F5E1E2"
                _hover={{
                  color: '#F5E1E2',
                }}
              >
                <Link isExternal href="https://tatjanacoaching.thrivecart.com/tapping-into-love-masterclass/">
                  Click here to purchase my masterclass
                </Link>
              </Button>
            </Flex>

          </Stack>
        </Flex>

      </Box>
    </Box >
  );
};

export default LoveMasterclass;