import { Box, Divider, Heading, Text, useBreakpointValue, VStack, Flex, Image } from '@chakra-ui/react';
import React from 'react';
import Home from "../../images/home.jpeg";

const AboutMe = () => {
  const lightBrownWithOpacity = 'rgba(210, 180, 140, 0.1)';
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box fontSize="20px" bgColor={'#DFCECE'} color="#7B6E7B" p={'1% 3%'}>
      <Box
        textAlign="center"
        position="relative"
        p={2}
        _after={
          !isMobile
            ? {
              content: '""',
              position: 'absolute',
              width: '55%', // Set the width to 75%
              height: '1.85px', // Adjust height as needed
              bottom: 0,
              left: '22.5%', // Center the underline
              backgroundColor: '#7B6E7B', // Your underline color
              paddingTop: '0.5px', // Adjust padding as needed
            }
            : {}
        }
      >
        <Heading
          as={'h2'}
          textAlign={'center'}
          fontFamily="Noto Serif, serif"
          fontSize={isMobile ? '2.2em' : '3.45em'}
          fontWeight={300}
          color="#7B6E7B"
        >
          A message from Tatjana
        </Heading>
      </Box>
      <Box p={3} mb={8} background="linear-gradient(#7B6E7B, #7B6E7B) bottom left / 8em 0.125em, linear-gradient(#7B6E7B, #7B6E7B) bottom left / 0.125em 3em, linear-gradient(#7B6E7B, #7B6E7B) top right / 8em 0.125em, linear-gradient(#7B6E7B, #7B6E7B) top right / 0.125em 3em; background-repeat: no-repeat;">
        <Heading mb="-6%" fontFamily="Lato, serif" color="#7B6E7B" fontSize="3em">
          &#x275D;
        </Heading>

        {/* Flex container to position the image and text next to each other */}
        <Flex direction={isMobile ? 'column' : 'row'} align="center" gap={4} p={isMobile ? 1 : 10}>
          {/* Image on the left side */}
          <Box flexShrink={0}>
            <Image
              src={Home} // Replace with your image source
              alt="Tatjana"
              boxSize="28em"
              objectFit="cover"
              borderRadius="8px"
            />
          </Box>

          {/* Text content on the right side */}
          <VStack
            align="flex-start"
            gap={4}
            fontWeight={300}
            fontFamily="Lato, serif"
            fontSize="1.2em"
            backgroundColor={lightBrownWithOpacity} // Set the background color with opacity
            color="#7b5e62"
            p={4}
            borderRadius="8px"
          >
            <Text>Hello, I am so happy that you found me.</Text>
            <Text>
              I am Tatjana, an Emotions and Spirituality Coach, Clinical EFT practitioner and Hypnotherapist, and I am a
              woman, a partner, and a mum of two beautiful girls. I was born in Munich, Germany and call now for more than 20
              years Australia my second home.
            </Text>
            <Text>
              I am also someone who has been where you are now and has persevered and is still using the tools and techniques,
              I will teach to you and use with you.
            </Text>
            <Text>
              You are here right now. It takes courage to change, but it is also incredibly rewarding. We all deserve a life we
              love. It is my offer to help you to love yours.
            </Text>
            <Text>Please contact me. I would love to hear your story!</Text>
            <Text>Love, Tatjana</Text>
          </VStack>
        </Flex>

        <Heading fontFamily="Lato, serif" color="#7B6E7B" fontSize="3em" mt="-4.5%" textAlign={'end'}>
          &#x275E;
        </Heading>
      </Box>
    </Box>
  );
};

export default AboutMe;
