import React from 'react';
import { Box, Center, Flex, Heading, List, ListIcon, ListItem, Text, VStack } from '@chakra-ui/react';
import Mirror from "../../../images/mirror.jpg";
import StyledHeader from '../../Styled-Header/styledHeader';
import { CheckIcon } from '@chakra-ui/icons';


interface HeroProps {
  title: string;
  subtext?: string;
  imageUrl: string;
  children?: any;
  reverseHeroPoints?: any
}

const ReverseHero: React.FC<HeroProps> = ({
  title,
  reverseHeroPoints,
  children
}) => {
  return (
    <>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
        bg="#D0A199"
        p="2%"
      >
        <Box
          position="relative"
          width="100%"
          height="100%"
          bgImage={Mirror}
          bgSize="cover"
          bgPosition="center"
          bgRepeat="no-repeat"
          borderRadius="lg"
          display="flex"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <Box
            position="absolute"
            bg="#E5DAD3"
            boxShadow="lg"
            p={3}
            left={{ base: '0', md: '0.75%' }}
            bottom={{ base: '0', md: '3%' }}
            maxW={{ base: '90%', md: '24em' }}
            mx={{ base: 'auto', md: 'initial' }}
            borderRadius="md"
          >
            <Box p="3%" border="2px solid #7b5e62">
              <Heading
                textAlign={'center'}
                p={2}
                pl={4}
                as="h2"
                fontSize={{ base: "1.6em", md: "1.7em" }}
                color="#7b5e62"
                fontFamily="Noto Serif, serif"
                pb={2}
                fontWeight={200}
              >
                You deserve to shine your unique light
              </Heading>
              <Heading
                p={2}
                pl={4}
                as="h2"
                fontSize={{ base: "1.4em", md: "1.7em" }}
                color="#7b5e62"
                fontFamily="Noto Serif, serif"
                pb={2}
                fontWeight={200}
              >
                I am here to support you in:
              </Heading>
              <VStack align="flex-start" gap={3} maxW="95%">
                <List p={4} pt={2} spacing={3} styleType="none">
                  {reverseHeroPoints.map((header: any) => (
                    <ListItem key={header}>
                      <Flex align="flex-start">
                        <ListIcon as={CheckIcon} color="#99817B" mt={1} fontSize="1.15em" />
                        <Heading
                          fontFamily="Lato, serif"
                          color="#99817B"
                          fontWeight="300"
                          fontSize={{ base: "1.2em", md: "1.4em" }}
                        >
                          {header}
                        </Heading>
                      </Flex>
                    </ListItem>
                  ))}
                </List>
              </VStack>
            </Box>
          </Box>
        </Box>
      </Box>

    </>
  );
};

export default ReverseHero;


