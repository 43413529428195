import { Button, useBreakpointValue } from '@chakra-ui/react';

const HeroForm = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <div id="mc_embed_shell">
      <style type="text/css">
        {`
          #mc_embed_signup {
            background: none;
            clear: left;
          },
        `}
      </style>
      <div id="mc_embed_signup">
        <form action="https://tatjanacoaching.us21.list-manage.com/subscribe/post?u=701f01cc851d1ab50bd6ccfd8&amp;id=6468a217cf&amp;f_id=00538ae6f0" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank">
          <div id="mc_embed_signup_scroll" style={{ minWidth: isMobile ? '19em' : '25em' }}>
            <div className="indicates-required" style={{ fontFamily: "Lato, serif", color: "#D27777", fontWeight: "300", height: 10, fontSize: "1.15em" }}>
              <span className="asterisk" style={{ color: '#D27777' }}>*</span> indicates required
            </div>
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL" style={{ fontFamily: "Lato, serif", color: "#7b5e62", fontWeight: "300", fontSize: "1.25em" }}>
                Email Address <span className="asterisk" style={{ color: '#D27777' }}>*</span>
              </label>
              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                required
                placeholder='Email address'
                style={{ background: 'none', border: '1.75px solid #99817B' }}
              />
            </div>
            <div hidden>
              <input type="hidden" name="tags" value="2869915" />
            </div>
            <div id="mce-responses" className="clear">
              <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
              <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
            </div>
            <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
              <input
                type="text"
                name="b_701f01cc851d1ab50bd6ccfd8_8377caa181"
                tabIndex={-1}
                value=""
              />
            </div>

            <div className="clear">
              <Button
                fontFamily="Lato, serif"
                fontWeight="300"
                rounded={'full'}
                variant="outline"
                borderColor="transparent"
                borderWidth={'1.75px'}
                transition={'0.3s'}
                fontSize={{ base: "1.27em", md: "1.4em" }}
                name="subscribe"
                id="mc-embedded-subscribe"
                value="Subscribe"
                type="submit"
                color="#7b5e62"
                _hover={{
                  background: '#AD9792',
                  color: '#E0D6D3'
                }}
              >
                Subscribe
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default HeroForm;
