import React from 'react';
import NavigationBar from '../containers/Navbar/Design-one/navbar';
import Footer from '../containers/Footer/footer';
import { Box, Center, Flex, Heading, Text, Image, ListItem, List, Stack, ListIcon, UnorderedList, VStack, SimpleGrid, useBreakpointValue, StackDivider } from '@chakra-ui/react';
import ResponsiveBgCard from '../components/Cards/Responsive-background';
import { ChevronRightIcon } from '@chakra-ui/icons';
import StyledHeader from '../components/Styled-Header/styledHeader';
import OneToOne from "../images/1:1.jpg";
import WinnieCarousel from '../components/Carousel/WinnieCarousel';
import Favicon from 'react-favicon';
import Logo from "../images/logo.png";
import One from "../images/1-1-image.webp";
import Fruitcake from "../images/fruitcake.jpeg";
import Farm from "../images/farm.jpeg";
import Park from "../images/park.jpg";

const Services = () => {
  const items = [
    "NLP & Empowerment Course for Women",
    "Make 2025 your best year yet",
    "Tapping Into Love",
  ];
  const oneToOnePoints = [
    'Cultivate a calmer nervous system, reducing hypervigilance, overthinking, emotional overload and anxiety',
    'Improve your overall mental and emotional health',
    'Reclaim your power from past traumatic events, regardless of when they occurred',
    'Process and release repressed emotions tied to those experiences',
    'Discover and embrace a more confident, authentic version of yourself',
    'Transform an anxious attachment style into feeling more secure',
    'Create new, implementable habits and patterns that empower you by supporting what you want',
    'For women: live more within your intuitive, magnetic, and receptive feminine energy',
    'Express your needs clearly and warmly',
    'Create more stable and intimate relationships',
    'Change overeating into intuitive eating and reduce emotional food cravings'
  ];
  const size = { width: "20px", height: "455px" }; // Uniform size for all boxes
  const colors = ["#C5ADC5", "#BDB6D8", "#D7D8F0"]; // Colors for the boxes
  const gaps = [355, 785, 1218]; // Horizontal offsets for spacing
  let ref1 = React.useRef<HTMLInputElement>(null);

  function scrollTo(ref: any) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <Favicon iconSize={32} url={Logo} />
      <NavigationBar scheduleACall={() => scrollTo(ref1)} buttons={[
        { label: 'ABOUT TATJANA', to: '/about', onClick: () => console.log('two') },
        { label: 'SERVICES', to: '/services', onClick: () => console.log('two') },
        { label: 'CLINICAL EFT, NLP & FEMININE ENERGY', to: '/clincaleft&nlp', onClick: () => console.log('two') },
      ]} />
      <Box p={'2%'}>
        <Box borderRadius={'xl'} backgroundColor={'#D7C5CB'} pos={'relative'}>
          <Box p={'.5% 5% 5% 5%'} borderRadius={'lg'} border='.75px solid #BDA6A9'>
            <Box pb={8} textAlign={'center'} pt={4}>
              <StyledHeader fontSize='4.25em' header='Services Tatjana offers' as='h2' />
            </Box>
            <VStack borderRadius={'3xl'} gap={6} w={'105%'} ml='-2.5%' pos={'relative'} p='0.5%'>
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={1} justifyContent={'center'} alignItems={'center'}>
                <ResponsiveBgCard title="Courses & Workshops" description="Check out the courses Tatjana offers here..." imageSrc={Farm}>
                  <VStack align={'flex-start'} gap={2} pb={4}>
                    {items.map(header =>
                      <UnorderedList >
                        <ListItem as='h5' fontFamily='Lato, serif' fontWeight={350} fontSize='1.1em' color='#7b5e62'>
                          <Flex align="flex-start" >
                            <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1em'} />
                            <Text as='h5' textAlign={'start'} fontFamily='Lato, serif' fontWeight={350} fontSize='1.3rem' color='#7b5e62'>
                              {header}
                            </Text>
                          </Flex>
                        </ListItem>
                      </UnorderedList>
                    )}
                  </VStack>
                </ResponsiveBgCard>
                <ResponsiveBgCard title="Nutrition Consultations" description="Nutrition can be tricky, hear about what Tatjana has to offer" imageSrc={Fruitcake}>
                  <Text p={5} pt={0} textAlign={'start'} fontFamily='Lato, serif' fontWeight={350} fontSize='1.35em' color='#7b5e62'>
                    Receive a personalized one-hour nutrition consultation tailored to nurture your nervous system, reduce cravings and support your overall well-being.
                  </Text>
                </ResponsiveBgCard>
                <ResponsiveBgCard title="1:1 Therapy & Coaching" description={"Hear more about your personalised sessions here..."} imageSrc={Park}>
                  <Text p={5} pt={0} as='h5' textAlign={'start'} fontFamily='Lato, serif' fontWeight={350} fontSize='1.3em' color='#7b5e62'>
                    Experience personalized therapy and coaching with Tatjana, tailored for both men and women to achieve emotional balance and reduce anxiety. Unlock your potential with newfound confidence, energy, and positive habits.
                  </Text>
                </ResponsiveBgCard>
              </SimpleGrid>
              <Box position="absolute" height="600px" width="1200px" display={{ base: 'none', sm: 'none', md: 'flex' }} alignItems="center">
                {gaps.map((gap, index) => (
                  <Box
                    key={index}
                    width={size.width}
                    height={size.height}
                    backgroundColor={colors[index]}
                    borderRadius="xl"
                    position="absolute"
                    left={`${gap}px`}
                  />
                ))}
              </Box>
            </VStack>

            <Box pos={'relative'}>
              <Box pos={'absolute'} display={{ base: 'none', md: 'initial' }} right={'2%'} bottom={'1%'} >
                <Box color={'#bca1aa'} fontSize={'8.75em'} h={'0.2em'} >
                  <span >&#10550;</span>
                </Box>
              </Box>
            </Box>







            <Flex direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" justifyContent="center" mt={'10%'} gap={5} mb={2}>
              <Stack maxW={{ base: '100%', md: '85%' }} mt={{ base: 2, md: 0 }} flex={1} gap={2}>
                <Image zIndex={1000} src={OneToOne} borderRadius={'xl'} />
              </Stack>
              <Box zIndex={1000} maxW={{ base: '100%', md: '70.5%' }} mt={{ base: 4, md: 6 }} >
                <Stack gap={3} bgColor={'#DED0D5'} p={8} pl={10} borderRadius={'xl'} boxShadow={'xl'} border={'2px solid #D7C5CB'}>
                  <Stack >
                    <Heading fontFamily='Noto Serif, serif' fontWeight={200} color="#7b5e62" fontSize={{ base: '2.35em', md: '2.9em' }} pb={2} >
                      In our 1:1 sessions, I am helping you to...
                    </Heading>
                  </Stack>
                  <Box >
                    <List spacing={3} flex={1}>
                      {oneToOnePoints.map((item, index) => (
                        <ListItem as='h5' fontFamily='Lato, serif' key={index} fontWeight={350} fontSize='1.1em' color='#7b5e62'>
                          <Flex align="flex-start" >
                            <Box>
                              <Flex align="flex-start">
                                <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1.25em'} />
                                <Text as='h5' fontSize={'1.25em'}> {item}</Text>
                              </Flex>
                            </Box>
                          </Flex>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Stack>
              </Box>
            </Flex>


            <Center>
              <Box w={{ base: 0, md: '50em' }} minH={'51em'} borderRadius={'xl'} bottom={'1.85%'} zIndex={1} pos={'absolute'} bgColor={'#C2A9B6'}>
              </Box>
            </Center>


          </Box>
        </Box>
      </Box >
      <WinnieCarousel />
      <div ref={ref1}>
        <Footer />
      </div>
    </>
  );
};

export default Services;