import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '@fontsource/barlow/900.css';
import Home from "./pages/home";
import About from "./pages/about";
import Services from "./pages/services";
import CoursesAndWorkshops from "./pages/coursesAndWorkshops";
import ClinicalEFT from "./containers/Clinical-EFT/clinicalEFT";
import EmpWoman from './pages/empWoman';
import BestYearYet from './pages/2025';


export const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route index path='/' element={<Home />} />
        <Route index path='about' element={<About />} />
        <Route index path='services' element={<Services />} />
        <Route index path='clincaleft&nlp' element={<ClinicalEFT />} />
        <Route index path='courses&workshops' element={<CoursesAndWorkshops />} />
        <Route index path='theempoweredfemininewoman' element={<EmpWoman />} />
        <Route index path='make2025yourbestyearyet' element={<BestYearYet />} />
      </Routes>
    </BrowserRouter>
  )
}
