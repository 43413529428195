import { useBreakpointValue, Stack, Box, Image, Text, Center, } from '@chakra-ui/react';
import FreebieForm from '../Email-Form/realFreebie';
import Freebie from "../../images/fem-energy.jpg"

const SignUp = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Stack
      background='linear-gradient(-70deg, #D0A199 48%, #e9dddd 48%, #e9dddd 52%, #E8DAD2 52%)'
      bgColor={'#E8DAD2'} p="2.5% 2%" direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" justifyContent="center" gap={10}>
      <Stack maxW={{ base: '100%', md: '40%' }} mt={{ base: 4, md: 0 }} flex={1} gap={2}>
        <Image src={Freebie} borderRadius={'xl'} opacity={0.985} />
      </Stack>
      <Box maxW={{ base: '100%', md: '47.5%' }} p={1} borderRadius="md" >
        <Box
          borderRadius="md"
          textAlign="center"
          border='3px solid #7b5e62'
          mx="auto"
          bgColor={'#E5DAD3'}
          p={8}
        >
          <Text as="h1" fontSize="4xl" fontWeight="bold" borderRadius={'md'}>
            <Text
              as="span"
              fontSize={isMobile ? '1.3em' : '1.9em'}
              pb={2}
              lineHeight={1.15}
              fontWeight="300"
              display="inline-block"
              fontFamily="Noto Serif, serif"
              color="#7b5e62"
            >
              Are you ready for more love?
            </Text>{' '}
          </Text>
          <Center>
            <Text mt={1}
              fontFamily="Lato, serif"
              color="#99817B"
              fontWeight="300"
              fontSize={{ base: "1.35em", md: "1.4em" }}
            >
              Get the FREE 5 step video training how to respond more effectively when you feel triggered
            </Text>
          </Center>
          <FreebieForm />
          {/* <Center>
              <MailchimpForm />
            </Center> */}
        </Box>
      </Box>
    </Stack>
  );
};

export default SignUp;