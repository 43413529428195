import "react-multi-carousel/lib/styles.css";
import Carousel from "react-multi-carousel";
import { Box, Divider, Flex, Heading, Text } from '@chakra-ui/react';


const WinnieCarousel = () => {
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 564, min: 0 },
            items: 1
        }
    };

    return (
        <Box p={{ base: '1em', md: 10 }} bg='#F8DCE7'>
            <Box pl={'2em'} pr='1em' border={'4px solid #7b5e62'} bgColor={'#F6D2E1'}>
                <Heading fontFamily='Noto Serif, serif'
                    fontSize={{ base: '3em', md: '6em' }}
                    textAlign='start'
                    p={4} mt={{ base: '0.5em' }} fontWeight={200} color='#7B6E7B' maxW={{ lg: '70%' }}>Words from people who trust Tatjana</Heading>
                <Carousel
                    autoPlay={true}
                    autoPlaySpeed={4000}
                    pauseOnHover={true}
                    infinite={true}
                    arrows
                    swipeable
                    responsive={responsive}
                >
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>Tatjana seemed to <Text as='i' fontSize='26px'>understand me without me having to explain.</Text> She really helped me to believe in myself more, to trust in my ability to heal myself and to know what to eat and when. She is able to coach very spontaneously and generously. I highly recommend her!</Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Shilpa Shah</Heading>
                    </Box>
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>I started working with Tatjana in September 2021, after struggling with disordered eating for about 7 years. I had already received support from a psychologist that helped me ‘maintain’ my disordered eating but I was frustrated by how it still seemed to be a constant in my life that I couldn’t quite shake, that was until my first meeting with Tatjana. After working with Tatjana over several sessions, I am delighted to say that I really do live my life intuitively. I don’t want it to sound overly simplistic because our sessions involved reflecting on where my challenges with food stemmed from and utilizing a number of different practices to reprogram my approach and thoughts about food. Not only do I feel like I have shifted my approach to eating, how to love and respect my body, but I also have a mental ‘toolbox’ full of resources to deploy if I ever feel like my mind is challenging me, or If I am presented with a situation that used to make me uncomfortable. It was a friend who shared that they work with Tatjana and recommended I meet with her, it’s the best decision I have ever made, and I am so grateful for the work we have done together, it has changed my life.</Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Emma</Heading>
                    </Box>
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>I can’t recommend Tatjana enough! She is an angel! I’ve worked with Tatjana over the past few years for one-on-one hypnotherapy, coaching and group NLP courses. She embodies everything I wish to be and I can’t thank her enough for changing my life. Tatjana doesn’t make you keep re-booking sessions unnecessarily, she is totally genuine. You won’t regret it.</Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Deborah Eastwood</Heading>
                    </Box>
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>
                            I first started seeing Tatjana 5 years ago when I felt I was at the lowest point in my life. I was living in constant anxiety and fear, grieving the loss of a loved one, afraid to make changes to my life and circumstances. Since I started seeing her, magic has come into my life, transforming it for the better once and for all... getting out of a toxic relationship, obtaining my permanent residency visa and citizenship in Australia, changing careers and landing the job of my dreams, finding the perfect partner, getting married and becoming pregnant easily, and most recently, passing my driving test — a fear I had carried for so long. These are all tangible accomplishments that happened thanks to Tatjana's work with me.
                            I also feel so much happier, calmer, more confident and positive thanks to her. Whenever I feel low or need help with achieving my goals, I just schedule a session with Tatjana, and the world inside and outside of me changes in an instant.
                        </Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- A</Heading>
                    </Box>
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>
                            The reason that I have worked with Tatjana is that she really got to the root of my childhood programming and rewired inaccurate thoughts and through her coaching I became the partner that I wanted to attract! She is like no other coach I have ever worked with, and I have had numerous friends work with her after seeing my
                            She is the queen of understanding and helping women find love again after divorce, without Tatjana, I wouldn't be the person, wife or mother I am today!
                        </Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Alison K</Heading>
                    </Box>
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>Thank you so much for everything. I feel like I have a different set of eyes that I am now seeing life through.</Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Natalie Sakre</Heading>
                    </Box>
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>I just wanted to let you know everything is going so well and I am so happy. Derek is amazing and we said the L word on the weekend when we went away to Arlie Beach xxxx thank you for all your help!</Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Anja</Heading>
                    </Box>

                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>I was at a really dark place in my life when I started seeing Tatjana. I felt purposeless and lost. Tatjana really helped me first to be honest to myself, accept myself for who I am and slowly, to appreciate me for me. I used to constantly look for external validation and now I have learned to see myself and fulfill my own needs of love, validation and approval. Tatjana helped me unlock this self-love. I really appreciate everything Tatjana did to help me and support me through this journey and I would highly recommend her.</Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Komal</Heading>
                    </Box>

                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>
                            I had my first EFT session with Tatjana. In a matter of minutes, I went from feeling tense and emotional, to soft, relaxed, and clear about the way forward. It was phenomenal! Days later this great feeling is still here. I am hooked.
                        </Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Naomie Thompson</Heading>
                    </Box>


                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>
                            Before working with Tatjana, I believed that feeling constantly anxious and stressed was normal, as it seemed everyone around me felt the same. However, after delving into my triggers and childhood trauma with Tatiana and addressing these recurring stressors with her through tapping EFT and hypnosis, I learned how to soothe my nervous system, leading to a transformative shift in my life. Insignificant things that I used to worry about don't bother me anymore, and I've learned how to manage my triggers more effectively when they arise.

                            Tatjana taught me the importance of setting healthy boundaries in a kind and loving way, this has been a game changer for me because it's made me realise that I am in control of my life and that where I choose to place my energy and time. Thank you, Tatjana. I highly recommend her guidance to those seeking a calmer, more balanced life.
                        </Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Jasmine Ford</Heading>
                    </Box>
                    <Box p={2}>
                        <Heading mb='-3.5%' fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275D;</Heading>
                        <Heading as='h5' p={5} pt={0} pb={0} fontFamily='Lato, serif' fontWeight={300} color="#766460" fontSize='22px'>
                            I highly recommend working with Tatjana. Her insightful guidance, qualified techniques and unwavering support have truly transformed my life.

                            With Tatjana’s help, I have been able to break through mental barriers created by my own childhood trauma and limiting beliefs.

                            I have also learnt the art of setting healthy boundaries, clear goals, and achieve success in both my personal and professional life.

                        </Heading>
                        <Flex justify='space-around' align='center' gap={6} borderColor='#7B6E7B' mt='-4%'>
                            <Divider w='65%' borderWidth='0.8px' />
                            <Heading fontFamily='Lato, serif' color='#7B6E7B' fontSize='3em' alignItems='center'>&#x275E;</Heading>
                        </Flex>
                        <Heading as='h5' p={5} pt={0} pb={0} mt={'-4%'} fontFamily='Noto Serif, serif' fontWeight={200} color="#766460" fontSize='22px'>- Roger B</Heading>
                    </Box>

                </Carousel>
            </Box >
        </Box >

    );
};

export default WinnieCarousel;