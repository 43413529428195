import { Box, Heading, useBreakpointValue, VStack } from '@chakra-ui/react';
import React, { useState } from 'react';
import HeroForm from '../../components/Email-Form/heroForm';

interface DesignHeaderProps {
  imageUrl: string;
  title: string;
  children?: any;
}

const DesignHeader: React.FC<DesignHeaderProps> = ({ imageUrl, title, children }) => {
  const [isHovered, setIsHovered] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      backgroundPosition='center'
      backgroundSize='cover'
      backgroundRepeat='no-repeat'
      maxW='100vw'
      display='flex'
      justifyContent='flex-start'  // Keep content left-aligned
      alignItems='center'
      backgroundImage={imageUrl}
      p={5}
      pt={10}
      pl={{ base: 5, lg: 7 }}  // Adjust padding for mobile
    >
      <VStack
        gap={{ base: 3, md: 1 }}
        pb={{ base: 4 }}
        maxW={{ base: '100%', md: '45em' }}  // Adjust width for smaller screens
        margin='5%'
        align='flex-start'  // Keep text left-aligned
      >
        <Heading
          as="h1"
          fontSize={{ base: '1.5em', md: '3em' }}  // Adjust font size for smaller screens
          mt={isMobile ? '10%' : 0}
          pb={4}
          fontFamily='Nato Serif, serif'
          color="#7b5e62"
          fontWeight="500"
          textAlign='left'  // Ensure left-alignment
        >
          {title}
        </Heading>
        <Heading
          as='h3'
          fontFamily='Lato, serif'
          maxW={{ base: '90%', md: '40vw' }}  // Adjust width for smaller screens
          color={'#99817B'}
          fontWeight='300'
          fontSize={{ base: '1.2em', md: '1.8em' }}  // Adjust font size for smaller screens
          textAlign='left'  // Ensure left-alignment
        >
          I invite you to subscribe to my monthly newsletter. It is time to shine your unique light.
        </Heading>
        <HeroForm />
      </VStack>
    </Box>

  );
};

export default DesignHeader;