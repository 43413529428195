import React from 'react';
import NavigationBar from '../containers/Navbar/Design-one/navbar';
import Footer from '../containers/Footer/footer';
import LoveMasterclass from '../containers/Love-Masterclass/loveMasterclass';
import WinnieCarousel from '../components/Carousel/WinnieCarousel';

const CoursesAndWorkshops = () => {
  let ref1 = React.useRef<HTMLInputElement>(null);

  function scrollTo(ref: any) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }


  return (
    <>
      <NavigationBar scheduleACall={() => scrollTo(ref1)} buttons={[
        { label: 'ABOUT TATJANA', to: '/about', onClick: () => console.log('two') },
        { label: 'SERVICES', to: '/services', onClick: () => console.log('two') },
        { label: 'CLINICAL EFT, NLP & FEMININE ENERGY', to: '/clincaleft&nlp', onClick: () => console.log('two') },
      ]} />
      <LoveMasterclass />
      <WinnieCarousel />
      <div ref={ref1}>
        <Footer />
      </div>
    </>
  );
};

export default CoursesAndWorkshops;