import { Box, Button, Heading, useBreakpointValue, VStack } from "@chakra-ui/react";
import BannerImage from "../../../images/holdinghands.jpg"

interface BannerProps {
  ml?: string;
  header: string;
  subText: string;
  bgColour: string;
  hoverColour?: string;
  onClick: () => void;
}

const Banner: React.FC<BannerProps> = ({
  ml,
  header,
  subText,
  bgColour,
  hoverColour,
  onClick,
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box
      zIndex={1}
      width="100vw"
      height={{ base: "25em", md: "400px" }} // Adjust height for smaller screens
      ml={ml}
      p={5}
      position="relative"
      bg={bgColour} // Background color
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        bgImage: BannerImage, // Placeholder background image
        bgSize: "cover",
        bgPosition: "center",
        bgRepeat: "no-repeat",
        opacity: 0.3, // Ensure transparency of the background image
        zIndex: 0,
      }}
    >
      {/* Content Wrapper */}
      <VStack
        position="relative"
        zIndex={1}
        height="100%"
        justify="center"
        align="center"
        color="white"
        textAlign="center"
        spacing={4}
        px={4} // Add padding for better spacing on smaller screens
      >
        <Heading
          fontSize={{ base: "2em", md: "2.3em" }} // Adjust font size for responsiveness
          fontWeight="100"
          fontFamily="Noto Serif, serif"
        >
          {header}
        </Heading>
        <Heading
          maxW={{ md: "40%" }} // Limit width for smaller screens
          fontSize={{ base: "1.35em", md: "1.75em" }} // Adjust font size
          fontWeight="100"
          fontFamily="Noto Serif, serif"
        >
          {subText}
        </Heading>
        <Button
          mt={4}
          p={{ base: 4, md: 7 }} // Adjust padding for smaller screens
          fontFamily="Lato, serif"
          fontWeight="300"
          rounded="full"
          variant="outline"
          borderColor="white"
          borderWidth="2.5px"
          fontSize={{ base: "1.15em", md: "1.25em" }} // Adjust font size
          onClick={onClick}
          color="white"
          _hover={{
            borderColor: hoverColour,
          }}
        >
          LEARN MORE
        </Button>
      </VStack>
    </Box>
  );
};

export default Banner;
