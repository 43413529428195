import { Box, Center, Divider, Flex, Heading, Icon, Image, ListIcon, ListItem, StackDivider, Text, UnorderedList, useBreakpointValue, VStack } from '@chakra-ui/react';
import React from 'react';
import AccreditationsImage from '../../images/accreditationsImage.jpg';
import { ChevronRightIcon } from '@chakra-ui/icons';

const Accreditations: React.FC = () => {
  const accreditations = [
    'Evidence Based EFT Practitioner (certified by Evidence Based EFT)',
    'Certified NLP Trainer (by the Tad James Training Company)',
    'Certified Clinical EFT Practitioner (by EFT Universe)',
    'Coaching Certification (by Scott Harris)',
    'Relationship Coach (trained by Rori Raye)',
    'Understanding Men & Women full curriculum certification (by Alison Armstrong',
    'Certified master practitioner of NLP Coaching, Timeline Therapy® and Hypnosis (by the Tad James Training Company)',
    'Certified Life Coach (by Andreas Winter, Germany)',
    'Energetic Health & Healing Practitioner Certification (by Penny Sharp)',
    'Certified member of the American Board of NLP and Timeline Therapy.',
    'Adv. Diploma of Naturopathy, Adv. Diploma of Nutritional Science, Adv. Diploma of Western Herbal Medicine (by ACNT College Sydney and Charles Sturt University)',
    'Basis and Master Course Certification “Hypnotic Techniques and NLP” (certified by Hypnose-Institut Deutschland, Germany)',
  ];

  const last = accreditations.slice(-1)[0];
  const isMobile = useBreakpointValue({ base: true, md: false });


  return (
    <Flex direction={{ base: 'column', md: 'row' }} wrap="wrap" w="100%">
      {isMobile ? (
        <VStack align="flex-start" color="#7b5e62" gap={4} justifyContent="center" w="100%">
          <VStack pb={2} p="4" gap={3} align="flex-start" bgColor="#DEC7B7" w="100%">
            <VStack p={4} border="1px solid #C4A292" w="100%">
              <Heading
                mt={1}
                fontFamily="Noto Serif, serif"
                textAlign="center"
                fontWeight={200}
                color="#7B6E7B"
                fontSize="2em"
                mb={2}
              >
                Tatjana's accreditations
              </Heading>
              <VStack align="flex-start" w="100%">
                {accreditations.map((accreditation, index) => (
                  <UnorderedList key={index} w="100%">
                    <ListItem
                      as="h5"
                      fontFamily="Lato, serif"
                      fontWeight={350}
                      fontSize="1.1em"
                      color="#7b5e62"
                    >
                      <Flex>
                        <Box>
                          <Flex p="2px 0">
                            <ListIcon
                              as={ChevronRightIcon}
                              color="#99817B"
                              mt={1}
                              fontSize="1.25em"
                            />
                            <Text as="h5" maxW="100%" fontSize="1em" wordBreak="break-word">
                              {accreditation}
                            </Text>
                          </Flex>
                        </Box>
                      </Flex>
                      {accreditation !== last && (
                        <Divider
                          maxW="80%"
                          ml="7%"
                          borderColor="#C2AFAB"
                          h="40%"
                          mt={2}
                        />
                      )}
                    </ListItem>
                  </UnorderedList>
                ))}
              </VStack>
              <Center mt={4} mb={2}>
                <Box w="90%">
                  <Image
                    borderRadius="sm"
                    src={AccreditationsImage}
                    alt="Tatjana"
                    objectFit="cover"
                  />
                </Box>
              </Center>
            </VStack>
          </VStack>
        </VStack>
      ) : (
        <>
          {/* Desktop Layout */}
          <VStack
            color="#7b5e62"
            gap={4}
            justifyContent="center"
            w="60%"
            p="4"
            bgColor="#DEC7B7"
          >
            <Heading
              mt={1}
              fontFamily="Noto Serif, serif"
              textAlign="center"
              fontWeight={200}
              color="#7B6E7B"
              fontSize="2.5em"
            >
              Tatjana's accreditations
            </Heading>
            <Divider borderColor="#C4A292" w="90%" borderWidth="1px" mb={2} />
            <VStack align="flex-start" w="100%">
              {accreditations.map((accreditation, index) => (
                <UnorderedList key={index} w="100%">
                  <ListItem
                    as="h5"
                    fontFamily="Lato, serif"
                    fontWeight={350}
                    fontSize="1.1em"
                    color="#7b5e62"
                  >
                    <Flex>
                      <Box>
                        <Flex p="2px 0" >
                          <ListIcon
                            as={ChevronRightIcon}
                            color="#99817B"
                            mt={1}
                            fontSize="1.5em"
                          />
                          <Text mt={1} as="h5" fontSize="1.25em" wordBreak="break-word">
                            {accreditation}
                          </Text>
                        </Flex>
                      </Box>
                    </Flex>
                    {accreditation !== last && (
                      <Divider maxW="80%" ml="7%" borderColor="#C2AFAB" h="40%" mt={2} />
                    )}
                  </ListItem>
                </UnorderedList>
              ))}
            </VStack>
          </VStack>
          <Box w="40%" h="100vh">
            <Image src={AccreditationsImage} alt="Tatjana" objectFit="cover" />
          </Box>
        </>
      )}
    </Flex>

  );
};

export default Accreditations;