import React from 'react';
import { Card, CardDescription, CardHeader, CardImage, CardOverlay, CardStatus, CardTitle, Cards, Root } from '../../Buttons/FlatButton/styles';

interface CardProps {
  title: string
  description: string
  imageSrc: string
  children: any
}


const ResponsiveBgCard: React.FC<CardProps> = ({ title, description, imageSrc, children }) => {
  return (
    <>
      <Root>
        <Cards>
          <li>
            <Card>
              <CardImage src={imageSrc} />
              <CardOverlay>
                <CardHeader>
                  <div>
                    <CardTitle>{title}</CardTitle>
                    <CardStatus>{description}</CardStatus>
                  </div>
                </CardHeader>
                <CardDescription>
                  {children}
                </CardDescription>
              </CardOverlay>
            </Card>
          </li>
        </Cards>
      </Root>
    </>
  );
};

export default ResponsiveBgCard;


