import { Flex, VStack, Heading, Box, Text, Stack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, useDisclosure, Center, HStack, Divider, useBreakpointValue, StackDivider } from '@chakra-ui/react';
import { faPhone, faAt, } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import FaIconButton from '../../components/Buttons/FontAwesomeIconButton';
import MailchimpForm from '../../components/Email-Form/freebieForm';


const Footer = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [hovered, setHovered] = useState(false);
  const isMobile = useBreakpointValue({ base: true, md: false });


  return (
    <Flex
      bg={'#FDF5F8'}
      justify={{ base: 'center', md: 'space-between' }}
      p={isMobile ? 0 : '2%'}
      pl={isMobile ? 0 : '2%'}
      maxH={'100%'}
      align={'center'}
      direction={{ base: 'column', md: 'row' }} // Stack vertically on small screens
    >
      {/* Newsletter Section */}
      <Flex
        color={'#7b5e62'}
        bg='#C9B9AC'
        align={'center'}
        maxW={{ base: '100%', md: '68%' }}  // Full width on small screens
        direction="column"
      >
        <VStack pl={{ base: '0%', lg: '2%' }} pt={'4%'} gap={0} pb={{ lg: 0 }} pr={{ lg: '0%' }}>
          {isMobile ?
            <Stack textAlign={'center'} w="100%" spacing={4} mt={2}>
              <Heading as='h4' size='md' fontFamily='Lato, serif' fontWeight='500' p={'8%'} pb={3}>
                Please text or email me to schedule an appointment or a completely free 15 min call for any questions you might have.
              </Heading>
              <Heading as='h4' size='md' fontFamily='Lato, serif' fontWeight='500' pb={2}>
                I always feel happy to hear from you.

              </Heading>
              <Heading as='h4' size='md' fontFamily='Lato, serif' fontWeight='500'>
                Much love, Tatjana
              </Heading>
              <Center>
                <Divider maxW={'60%'} mt={5} borderColor={'e9dddd'} />
              </Center>
              <Box p={'5%'}>
                <Center>
                  <Heading maxW={'93%'} as='h3' fontSize={{ base: '1.4em', md: '1.8em' }} fontFamily='Noto Serif, serif' fontWeight='100'>
                    Subscribe to my fortnightly newsletter
                  </Heading>
                </Center>
                <MailchimpForm />
              </Box>

            </Stack>
            :

            <HStack align={'center'} p={'0% 4%'} pl='3%'>
              <Flex>
                <Stack textAlign={'center'} gap={10} mb={'30%'}>
                  <Center mt={6}>
                    <Heading as='h4' fontSize='1.4em' fontFamily='Lato, serif' fontWeight='500' maxW={'100%'}>
                      Please text or email me to schedule an appointment or a completely free 15 min call for any questions you might have.
                    </Heading>
                  </Center>
                  <Heading as='h4' fontSize='1.4em' fontFamily='Lato, serif' fontWeight='500'>
                    I always feel happy to hear from you.
                  </Heading>
                  <Heading as='h4' fontSize='1.4em' fontFamily='Lato, serif' fontWeight='500'>
                    Much love, Tatjana
                  </Heading>
                </Stack>
              </Flex>
              <Center>
                <Box w={'0.5px'} bgColor={'#BCA7A7'} ml={3} h={'50vh'} />
              </Center>
              <Box minW={'55%'} p={'2.5% 3.5%'} pl={0}>
                <Center>
                  <Heading textAlign={'center'} as='h3' fontSize={{ base: '1.8em', md: '1.9em' }} fontFamily='Noto Serif, serif' fontWeight='100'>
                    Subscribe to my monthly newsletter
                  </Heading>
                </Center>
                <Box >
                  <MailchimpForm />
                </Box>
              </Box>
            </HStack>
          }
        </VStack>
      </Flex>

      {/* Contact Section */}
      <VStack
        color={'#7b5e62'}
        align='start'
        maxW={{ base: '100%', md: '28%' }} // Full width on small screens
        pt={{ base: 5, md: 0 }} // Padding adjustment for mobile
      >
        <Heading w="100%" as='h2' fontSize={{ base: '1.9em', md: '5em' }} lineHeight={1.1} p={isMobile ? 4 : 5} mb={2} fontFamily='Noto Serif, serif' fontWeight='100'>
          Become who you love to be
        </Heading>

        <VStack align={'flex-start'} p={5}>
          <Flex gap={3} align='center' justify={'center'}>
            <FaIconButton icon={faPhone} arialabel='mobile-contact' onClick={() => console.log('test')} />
            <Heading as='h4' size='md' fontFamily='Lato, serif' fontWeight='500'>0405 349 185</Heading>
          </Flex>
          <Flex gap={3} align='center' justify={'center'}>
            <FaIconButton icon={faAt} arialabel='email-contact' onClick={() => console.log('test')} />
            <Heading as='h4' size='md' fontFamily='Lato, serif' fontWeight='500'>tatjana@tatjanacoaching.com</Heading>
          </Flex>

          <Box textAlign="center" mt={6}>
            <Heading as='h4' size='sm' fontFamily='Lato, serif'
              onClick={onOpen}
              onMouseEnter={() => setHovered(true)}
              onMouseLeave={() => setHovered(false)}
              cursor="pointer"
              color={hovered ? '#9F9693' : '#7b5e62'}  // Change text color on hover
            >
              Terms & Conditions
            </Heading>

            {/* Pop-up Modal */}
            <Modal isOpen={isOpen} onClose={onClose} isCentered size={'xl'} >
              <ModalOverlay />
              <ModalContent backgroundColor="#C9B9AC" border='1px solid #7b5e62' p={2}>
                <ModalHeader color={'#7b5e62'}>Terms & Conditions</ModalHeader>
                <ModalCloseButton color={'#7b5e62'} />
                <ModalBody >
                  <VStack gap={3} pb={3}>
                    <Text>
                      These Terms of Use may be updated at any time at the sole discretion of the site owner and operator. Users are encouraged to regularly review the site for any changes.
                    </Text>
                    <Text>
                      Emotional Freedom Techniques (EFT), while gaining scientific recognition, are still regarded as experimental and have not yet been fully validated as a scientific treatment method.
                      Neither Clinical EFT, NLP Coaching, nor any information provided on this site is intended to diagnose, treat, cure, or prevent any medical condition or disorder.
                    </Text>
                    <Text>
                      If you have concerns regarding your mental health, it is strongly advised that you seek consultation or treatment from a licensed psychologist, psychotherapist, or psychiatrist.
                    </Text>
                    <Text>
                      Any changes to prescribed medications, including discontinuation, reduction, or increase, should be made only after consultation with a licensed medical professional, such as a doctor or pharmacist.
                    </Text>
                  </VStack>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        </VStack>
      </VStack>
    </Flex >

  );
};

export default Footer;