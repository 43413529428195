import React from 'react';
import NavigationBar from '../containers/Navbar/Design-one/navbar';
import Footer from '../containers/Footer/footer';
import Accreditations from '../containers/Accreditations/accreditations';
import AboutMe from '../containers/About-Me/aboutMe';
import WinnieCarousel from '../components/Carousel/WinnieCarousel';
import Favicon from 'react-favicon';
import Logo from "../images/logo.png"

const About = () => {
  let ref1 = React.useRef<HTMLInputElement>(null);

  function scrollTo(ref: any) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }

  return (
    <>
      <Favicon iconSize={32} url={Logo} />
      <NavigationBar scheduleACall={() => scrollTo(ref1)} buttons={[
        { label: 'ABOUT TATJANA', to: '/about', onClick: () => console.log('two') },
        { label: 'SERVICES', to: '/services', onClick: () => console.log('two') },
        { label: 'CLINICAL EFT, NLP & FEMININE ENERGY', to: '/clincaleft&nlp', onClick: () => console.log('two') },
      ]} />
      <AboutMe />
      <Accreditations />
      <WinnieCarousel />
      <div ref={ref1} >
        <Footer />
      </div>
    </>
  );
};

export default About;