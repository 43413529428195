import React from 'react';
import NavigationBar from '../containers/Navbar/Design-one/navbar';
import Footer from '../containers/Footer/footer';
import { Box, Button, Center, Divider, Flex, Heading, HStack, Image, List, ListIcon, ListItem, Stack, Text, useBreakpointValue } from '@chakra-ui/react';
import Park from "../images/2025.jpg"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamation, faLightbulb } from '@fortawesome/free-solid-svg-icons';
import { ChevronRightIcon } from '@chakra-ui/icons';
import Banner from '../components/Banners/With-Sign-Up';
import Favicon from 'react-favicon';
import Logo from "../images/logo.png";
import Emp from "../images/emp.jpg";
import Empwoman from "../images/empwoman.jpg";
import Check from "../images/71.jpg";
import modelTwo from '../images/modelTwo.jpg'


const EmpWoman = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const listItems = [
    {
      number: '1.',
      title: 'Cultivate inner confidence:',
      description: 'Develop unwavering core confidence and a deep sense of self-worth.',
    },
    {
      number: '2.',
      title: 'Embrace Self-Love:',
      description: 'Finally understand self-love and cultivate a love for yourself that will lead you throughout your life.',
    },
    {
      number: '3.',
      title: 'Be The Empowered, Feminine Woman:',
      description: 'Take back your personal power and feel that your life is yours instead of fearing judgement of other people.',
    },
    {
      number: '4.',
      title: 'Communication Skills:',
      description: 'Enhance your ability to connect, communicate clearly (even over the phone or on text), and build authentic relationships with those around you.',
    },
    {
      number: '5.',
      title: 'Understanding people:',
      description: 'Learn how to understand people easier (including teenagers) and learn the differences between men and women in terms of communication.',
    },
    {
      number: '6.',
      title: 'Reconnect with Your Heart and Body:',
      description: 'Move beyond overthinking and reconnect with your heart, intuition & authentic feminine energy.',
    },
    {
      number: '7.',
      title: 'Release Limiting Beliefs:',
      description: 'Let go of outdated beliefs (like “I am not good enough”) that no longer serve you and learn how to spot them.'
    },
    {
      number: '8.',
      title: 'Master Your Emotions:',
      description: 'Learn the art of emotional intelligence and how to apply it in your daily life. This will also help you to make decisions with more clarity.',
    },
    {
      number: '9.',
      title: 'Boundary Setting with Grace:',
      description: 'Learn how to set and keep boundaries in a warm and respectful way and discover what your boundaries actually are.',
    },
    {
      number: '10.',
      title: 'Overcome Anxiety:',
      description: 'Learn where it comes from and how to transform it … and much more.',
    },
  ];
  const bulletListItems = [
    'Personal growth and development',
    'Greater self-confidence',
    'Overcoming barriers and insecurities when communicating in any environment.',
    'Letting go of overthinking and self-doubt',
    'Respond thoughtfully instead of reacting impulsively.',
    'More meaningful relationships at work and in your personal life',
  ];

  let ref1 = React.useRef<HTMLInputElement>(null);

  function scrollTo(ref: any) {
    if (!ref.current) return;
    ref.current.scrollIntoView({ behavior: "smooth" });
  }
  const element = document.getElementById("box");

  return (
    <>
      <Favicon iconSize={32} url={Logo} />
      <NavigationBar scheduleACall={() => scrollTo(ref1)} buttons={[
        { label: 'ABOUT TATJANA', to: '/about', onClick: () => console.log('two') },
        { label: 'SERVICES', to: '/services', onClick: () => console.log('two') },
        { label: 'CLINICAL EFT, NLP & FEMININE ENERGY', to: '/clincaleft&nlp', onClick: () => console.log('two') },
      ]} />

      <Stack p='2.5% 5.5%' gap={'0.4em'} bgColor={'#ffeada'} border='15px solid #d5aca9' >
        <Flex direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" justifyContent="center" gap={10}>
          <Box maxW={{ base: '100%', md: '55%' }} >
            <Stack gap={6}>
              <Stack>
                <Heading fontFamily='Noto Serif, serif' fontWeight={200} color="#7b5e62" pb={2} fontSize={{ base: '3.2em', md: '4.5em' }}>
                  The Empowered, Feminine Woman
                </Heading>
                <Heading fontFamily='Noto Serif, serif' maxW={{ md: '80%' }} fontWeight={200} color="#7b5e62" fontSize={'1.7em'}>
                  A 4 day NLP Course For women in business leadership & motherhood & all women who want to deepdive into their magnetism.
                </Heading>




              </Stack>
              <Heading fontFamily="Lato, serif"
                fontWeight="300" color="#7b5e62" fontSize={'1.35em'}>
                My courses are designed to be intimate and supportive, creating a nurturing, fun environment for all participants. As such, I am limiting this course to just five places.
              </Heading>
              <Box maxW={'70%'} h={'2px'} mt={4} bgColor={'#E1CCBD'}></Box>
            </Stack>



          </Box>
          <Stack maxW={{ base: '100%', md: '35%' }} mt={{ base: 4, md: 8 }} flex={1} gap={2}>
            <Image src={Empwoman} borderRadius={'sm'} boxShadow={'xl'} />
            <Heading zIndex={1} fontFamily="Lato, serif" fontWeight="300" color="#7b5e62" fontSize={'1.35em'}>
              The next course starts Friday, 16/05/25
            </Heading>
            <Box pos={'absolute'} zIndex={-0.1} left={'5%'} opacity={0.8} w={'2.5px'} h={'25em'} top={'6.5%'} bgColor={'#E7CBC9'} />

          </Stack>
        </Flex>

        <Center mt={'5.5%'} mb={5} bgColor={'#F6DED0'} borderRadius={'xl'}>
          <Stack p={5} pl={2} pr={2}>
            <Flex maxW={'100%'} justify={'center'} gap={2} align={'center'}>
              <HStack >
              </HStack>
              <Text textAlign={'center'} fontFamily="Lato, serif"
                borderRadius={'xl'}
                color="#806466"
                fontWeight="300"
                maxW={'85%'}
                fontSize={{ base: "1.27em", md: "1.35em" }} >
                NLP (Neuro Linguistic Programming) approached from a feminine energy point of view offers incredible tools and transformative mindset shifts. It encourages deeper inner confidence, awareness, and courage and better communication, relationship and connection skills leaving you more magnetic to people, money and your desires.
              </Text>
            </Flex>
          </Stack>
        </Center>

        <Heading mt={10} zIndex={1} mb={2} as='h3' fontFamily='Noto Serif, serif' color="#7b5e62" fontSize={{ base: '2.5em', md: '3em' }} fontWeight='200'  >
          What this course offers you:
        </Heading>
        <Flex pos={'relative'} direction={useBreakpointValue({ base: 'column', md: 'row' })} mb={8} alignItems="top" justifyContent="center" gap={10}>
          <Stack maxW={{ base: '100%', md: '68%' }} mt={{ base: 4, md: 0 }} flex={1} gap={2}>
            <List spacing={4} flex={1} zIndex={1}>
              {listItems.map((item, index) => (
                <ListItem key={index} pl={2} >
                  <Flex align="flex-start" mb={5} maxW={'97%'}>
                    <Text
                      as="span"
                      fontFamily='Noto Serif, serif'
                      color="#7b5e62"
                      fontWeight="bold"
                      fontSize="1.3em"  // Ensure the number has good visibility
                    >
                      {item.number}
                    </Text>
                    <Box ml={3}>
                      <Text
                        as="span"
                        color="#7b5e62"
                        fontWeight={400}
                        fontSize={item.title.endsWith(':') ? '1.5em' : 'md'}
                        fontFamily='Noto Serif, serif'
                      >
                        {item.title}
                      </Text>{'  '}

                      <Text as="span"
                        fontFamily="Lato, serif"
                        color="#7b5e62"
                        fontWeight="300"
                        fontSize={{ base: "1.27em", md: "1.35em" }}
                      >
                        {item.description}
                      </Text>
                    </Box>
                  </Flex>
                </ListItem>
              ))}
            </List>
          </Stack>
          <Stack maxW={{ base: '100%', md: '32%' }} mt={{ base: 4, md: 0 }} flex={1} gap={2}>
            <Image src={Check} zIndex={1} borderRadius={'xl'} />
          </Stack>
          <Box pos={'absolute'} right={'-1.35%'} opacity={0.8} w={'400px'} h={{ md: '580px' }} top={'4%'} borderRadius={'lg'} bgColor={'#E7CBC9'} />
          <Box pos={'absolute'} opacity={0.15} left={'-1.25%'} w={'450px'} h={{ md: '1035px' }} top={'-9%'} borderRadius={'xl'} bgColor={'#C19B98'} />
        </Flex>






        <Banner onClick={() => scrollTo(ref1)} hoverColour='#7b5e62' bgColour='#C19B98' header='Please text or email me for more information or to enrol.' subText='The next course date is Friday 16/05 - Sunday 18/05/25 & Sunday 25/05/25' ml={isMobile ? '-11%' : '-7.6%'} />


        <Flex pos={'relative'} direction={useBreakpointValue({ base: 'column', md: 'row' })} alignItems="center" mt={10} mb={2} justifyContent="center" gap={5}>
          <Stack maxW={{ base: '100%', md: '35%' }} mt={{ base: 4, md: 0 }} flex={1} gap={2}>
            <Image zIndex={1} src={modelTwo} borderRadius={'xl'} />
          </Stack>
          <Box maxW={{ base: '100%', md: '55%' }} p={5} zIndex={1} >

            <Stack gap={3}>
              <Heading fontFamily='Noto Serif, serif' fontWeight={200} color="#7b5e62" fontSize={'2em'}>
                If you are looking for:
              </Heading>

              <Box>
                <List spacing={3} flex={1}>
                  {bulletListItems.map((item, index) => (
                    <ListItem as='h5' fontFamily='Lato, serif' key={index} fontWeight={350} fontSize='1.1em' color='#7b5e62'>
                      <Flex align="flex-start" >
                        <Box>
                          <Flex align="flex-start">
                            <ListIcon as={ChevronRightIcon} color="#99817B" mt={1} fontSize={'1.25em'} />
                            <Text as='h5' fontSize={'1.25em'}> {item}</Text>
                          </Flex>
                        </Box>
                      </Flex>
                    </ListItem>
                  ))}
                </List>

                <Heading mt={6} fontFamily='Noto Serif, serif' fontWeight={200} color="#7b5e62" fontSize={'1.55em'} textAlign={'center'}>
                  Then this 4- day course is for you!
                </Heading>
              </Box>

            </Stack>
          </Box>
          <Box pos={'absolute'} w={useBreakpointValue({ base: '100%', md: '50em' })} right={'0%'} borderRadius={'xl'} h={useBreakpointValue({ base: '100%', md: '100%' })} bgColor={'#F6DED0'} />
        </Flex>
      </Stack >
      <div ref={ref1}>
        <Footer />
      </div>
    </>

  );
};

export default EmpWoman;